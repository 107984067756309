<template>
    <GmapMap
        :center="{ lat: default_lat, lng: default_lng }"
        :zoom="12"
        map-type-id="terrain"
        style="height: 150px"
        @click="addMarker"
    >
        <GmapMarker :position="newMarker" :clickable="true" />
    </GmapMap>
</template>

<script>
export default {
    props: {
        marker: {
            required: true
        },
        ifEdit: {
            type: Boolean,
        }
    },
    data(){
        return {
            default_lat: 25.2048,
            default_lng: 55.2708,
        };
    },
    computed:{
        newMarker: {
            get() {
                return this.marker
            },
            set(value) {
                this.$emit('update:marker', value)
            }
        },
    },
    methods:{
        addMarker(event) {
            this.newMarker = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }
            // this.$emit('marker-click', this.marker);
            // console.log(this.newMarker)
        },
    }

}
</script>

<style>
</style>