import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import HorseCategoryService from "../services/HorseCategoryService";

export const useHorseCategoryStore = defineStore("horseCategoryStore", {
    state: () => ({
        horseCategoryBase: new BaseState(),
        horseCategoryModel: {
            id: '',
            name_en: '',
            name_ar: '',
            description_en: '',
            description_ar: '',
        },
        horseCategoryList: [],
        show: false
    }),
    getters: {
    },
    actions: {
        clearHorseCategoryModel() {
            this.horseCategoryModel.id = ''
            this.horseCategoryModel.name_en = ""
            this.horseCategoryModel.name_ar = ""
            this.horseCategoryModel.description_en = ""
            this.horseCategoryModel.description_ar = ""
        },
        setHorseCategoryModel(item) {
            this.horseCategoryModel.id = item.id
            this.horseCategoryModel.name_en = item.name_en
            this.horseCategoryModel.description_en = item.description_en
        },
        addHorseCategory(formData) {
            this.horseCategoryBase.loading = true;
            HorseCategoryService.add(formData).then((res) => {
                this.horseCategoryBase.loading = false
                this.show = false
                this.fetchAllHorseCategory()
                this.clearHorseCategoryModel()
            })
                .catch((err) => {
                    this.horseCategoryBase.loading = false
                    this.horseCategoryBase.error = true
                });
        },
        fetchAllHorseCategory() {
            this.horseCategoryBase.loadingFetch = true
            HorseCategoryService.getAll().then((res) => {
                // console.log(res.data.data)
                this.horseCategoryList = res.data.data.reverse()
                this.horseCategoryBase.loadingFetch = false
                this.horseCategoryBase.errorFetch = false
            })
                .catch((err) => {
                    this.horseCategoryBase.loadingFetch = false
                    this.horseCategoryBase.errorFetch = true
                });
        },
        deleteHorseCategory(id) {
            this.horseCategoryBase.loading = true
            HorseCategoryService.delete(id).then((res) => {
                this.horseCategoryBase.loading = false
                this.horseCategoryList.splice(this.horseCategoryBase.editedIndex, 1)
                this.horseCategoryBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.horseCategoryBase.loading = false
                    this.horseCategoryBase.error = true
                })
        },
        getHorseCategoryById(id) {
            this.horseCategoryBase.loadingFetchItem = true
            HorseCategoryService.getById(id).then((res) => {
                this.horseCategoryBase.loadingFetchItem = false
                this.horseCategoryModel.id = res.data.data.id
                this.horseCategoryModel.name_en = res.data.data.name.en
                this.horseCategoryModel.name_ar = res.data.data.name.ar
                this.horseCategoryModel.description_en = res.data.data.description.en
                this.horseCategoryModel.description_ar = res.data.data.description.ar
            })
                .catch((err) => {
                    this.horseCategoryBase.loadingFetchItem = false
                    this.horseCategoryBase.error = true
                })
        },
        editHorseCategory(id, formData) {
            this.horseCategoryBase.loading = true;
            HorseCategoryService.edit(id, formData).then((res) => {
                this.horseCategoryBase.loading = false
                this.horseCategoryBase.ifEdit = false
                this.show = false
                this.fetchAllHorseCategory()
                this.clearHorseCategoryModel()
            })
                .catch((err) => {
                    this.horseCategoryBase.loading = false
                    this.horseCategoryBase.error = true
                });
        },
    },
});
