<template>
    <v-dialog v-model="dialogDetailsModal" max-width="700px">
        <v-card>
            <v-spacer></v-spacer>
            <v-card-title
                class="justify-content-start"
                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;"
            >{{$t('details')}}</v-card-title>
            <v-card-text style="padding-bottom: 40px;">
                <v-simple-table style=" padding: 0px 0px 20px; text-align: justify;">
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <th>{{ $t('auction.description') }}</th>
                                <td>{{ display.description }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('auction.start_time') }}</th>
                                <td>{{ display.start_time.slice(11, 16) }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('auction.end_time') }}</th>
                                <td>{{ display.end_time.slice(11, 16) }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('auction.insurance_value') }}</th>
                                <td>{{ display.insurance_value }}</td>
                            </tr>
                            <tr v-if="display.auction_horses.length != 0">
                                <th>{{ $t('auction.horse') }}</th>
                                <td>
                                    <ul>
                                        <li
                                            v-for="(item, index) in display.auction_horses"
                                            :key="index"
                                        >{{ item.name.en }}</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('auction.location') }}</th>
                                <td>
                                    <div style="margin: 20px 0px">
                                        {{ display.location_name }}
                                    </div>
                                    <GmapMap
                                        :center="{ lat: marker.lat, lng: marker.lng }"
                                        :zoom="12"
                                        map-type-id="terrain"
                                        style="height: 200px; width: 400px"
                                    >
                                        <GmapMarker :position="marker" />
                                    </GmapMap>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DetailsDialog",
    props: {
        dialogDetails: {
            type: Boolean,
            required: true
        },
        display: {
            type: Object,
            required: true
        }
    },

    computed: {
        dialogDetailsModal: {
            get() {
                return this.dialogDetails
            },
            set(value) {
                this.$emit('update:dialogDetails', value)
            }
        },
        marker() {
            return {
                lat: parseFloat(this.display.latitude),
                lng: parseFloat(this.display.longitude)
            }
        }
    },
}
</script>

<style>
</style>