<template>
    <div>
        <base-add-page-layout
            :breadEn="breadEn"
            :breadAr="breadAr"
            :cardTitle="cardTitle"
            :btnTitle="btnTitle"
            listRouteName="my-profile"
        >
            <template v-slot:form>
                <my-profile-form />
            </template>
        </base-add-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useUserStore } from "../store/UserStore"
import { mapState } from "pinia"
import BaseAddPageLayout from "@/layouts/BaseAddPageLayout.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import MyProfileForm from "../components/MyProfileForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseAddPageLayout,
        NoInternet,
        MyProfileForm,
    },
    data: () => ({
        cardTitle: "user.edit_profile",
        btnTitle: "user.profile",
        breadEn: [
            {
                text: 'My Profile',
                disabled: false,
                href: '/my-profile',
            },
            {
                text: 'Edit',
                disabled: false,
                href: '/my-profile/edit',
            },
        ],
        breadAr: [
            {
                text: 'حسابي الشخصي',
                disabled: false,
                href: '/my-profile',
            },
            {
                text: 'تعديل',
                disabled: false,
                href: '/my-profile/edit',
            },
        ],
    }),
    computed: {
        ...mapState(useUserStore, ['userBase']),
    },
    methods: {
    },
    mounted(){
        this.userBase.ifEdit = true
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
