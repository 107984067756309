<template>
    <v-btn
        :to="{name:`${listRouteName}`}"
        color="primary"
        outlined
    >{{ $t(`${title}`) }}</v-btn>
</template>

<script>
export default {
    name: "ListBtn",
    props: ['title','listRouteName'],
    data() {
        return {

        };
    },
    methods: {

    },
    mounted(){
        // console.log(this.listRouteName)
    }
};
</script>
