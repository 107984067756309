<template>
    <div class="no-data-image">
        <v-img src="@/assets/img/no-data.svg" width="400px"></v-img>
        <p class="text-muted" style="margin: 20px 0">You have never created a stable before</p>
        <v-btn color="secondary" @click="create">Create Your Stable Now</v-btn>
    </div>
</template>

<script>
export default {
    name:"NoData",
    methods: {
        create() {
            this.$emit('create')
        },
    },
}
</script>

<style>
@import url(@/assets/Css/main.css);
.no-data-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>