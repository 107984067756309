<template>
    <v-btn
        @click="$emit('click')"
        color="primary"
        light
        :loading="loading"
    >
        {{ $t('submit') }}
    </v-btn>
</template>

<script>
export default {
    name: "SubmitBtn",
    props: ['loading'],
};
</script>
