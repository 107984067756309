<template>
    <div>
        <v-text-field
            v-model="userBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('user.headers')"
            :items="userList"
            :search="userBase.search"
            class="my-table"
            :loading="userBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('user.email')}}: </span> {{ item.email }}
                    </div>
                    <br />
                    <div v-if="item.date_of_birth != null">
                        <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('user.date_of_birth')}}: </span> {{ item.date_of_birth.slice(0, 10) }}
                    </div>
                    <br />
                    <div v-if="item.mobile != null">
                        <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('user.mobile')}}: </span> {{ item.mobile }}
                    </div>
                    <br />
                    <div v-if="item.role_name[0] != null">
                        <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('user.role')}}: </span> {{ item.role_name[0] }}
                    </div>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="userBase.dialogDeleteModel"
                    :loading="userBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <display-image-dialog :dialog-image.sync="dialogImageModel" :image="image"></display-image-dialog>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
                <div
                    class="row justify-content-center"
                    style="top: 15px; position: relative;direction: ltr"
                >
                    <v-switch
                        v-model="item.is_active"
                        inset
                        color="green"
                        @click="isActiveItem(item)"
                    ></v-switch>
                </div>
            </template>
            <template v-slot:[`item.user_image`]="{ item }">
                <v-img
                    @click="displayImage(item)"
                    aspect-ratio="1"
                    :src="item.user_image"
                    :lazy-src="item.user_image"
                    class="grey lighten-2"
                    style="cursor: pointer;"
                    max-width="100"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import DisplayImageDialog from "@/components/dialogs/DisplayImageDialog.vue";
import { useUserStore } from "../store/UserStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog, DisplayImageDialog },

    data() {
        return {
            expanded: [],
            dialogImageModel: false
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useUserStore, ['userBase', 'userList']),
        ...mapWritableState(useUserStore, ['image']),

    },

    methods: {
        ...mapActions(useUserStore, ['fetchAllUser', 'deleteUser', 'changeIsActiveUser' ]),

        deleteItem(item) {
            this.userBase.editedIndex = this.userList.indexOf(item)
            this.userBase.delete = item
            this.userBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.userBase.loading = true
            this.deleteUser(this.userBase.delete.id)
        },

        editItem(item) {
            this.userBase.ifEdit = true
            this.$router.push("/user/edit/" + item.id)
        },

        isActiveItem(item){
            const formData = new FormData()
            formData.append('is_active',+(item.is_active))
            this.changeIsActiveUser(item.id, formData)
        },

        displayImage(item) {
            this.image = item.user_image
            this.dialogImageModel = true
        },

    },
    mounted() {
        this.fetchAllUser()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>