<template>
  <v-app :class="[isAr ? 'ar' : 'en']">
    <!-- <CustomToast ref="CustomToast" /> -->
    <div :class="[ifCollapsed ? 'collapsed' : 'not-collapsed']" v-if="!['login'].includes($route.name)">
      <v-main class="internal-main">
        <sidebar />
        <navbar />
        <router-view />
      </v-main>
    </div>
    <div v-else>
      <v-main class="external-main">
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Sidebar from '@/components/main/Sidebar'
import Navbar from '@/components/main/Navbar.vue'
import CustomToast from '@/components/main/CustomToast.vue'

import { useSidebarStore } from '@/store/SidebarStore'
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"

export default {
  name: 'App',
  components: {
    Sidebar,
    Navbar,
    CustomToast
  },
  data: () => ({
  }),
  computed: {
    ...mapState(useSidebarStore,['ifCollapsed']),
    ...mapState(useLangStore,['lang', 'isAr']),
  },
  mounted() {
    this.$root.CustomToast = this.$refs.CustomToast
  },
  created() {
    // let self = this
    // this.axios.interceptors.response.use(function (response) {
    //   return response
    // }, function (error) {
    //   console.log(error.response.data)
    //   if (error.response.data.message === "Unauthenticated.") {
    //     self.$router.push({ name: 'login' })
    //   }
    //   if (error.response.data.message == "Not Found") {
    //     self.$router.push({ name: 'not-found' })
    //   }
    //   else return error
    // })
  },
};
</script>

<style lang="scss">
html:lang(ar) * {
  letter-spacing: 0 !important;
}

.theme--light.v-application {
  background: #f2f2f2 !important;
}

// .v-main__wrap {
//   background-color: #f2f2f2;
// }

@media (max-width: 767px) {
  html:lang(en) .internal-main {
    padding-left: 65px !important;
  }

  html:lang(ar) .internal-main {
    padding-right: 65px !important;
  }
}

@media (min-width: 767px) {
  html:lang(en) .collapsed {
    padding-left: 65px;
    transition: 0.3s padding-left ease;
  }

  html:lang(en) .not-collapsed {
    padding-left: 300px;
    transition: 0.3s padding-left ease;
  }

  html:lang(ar) .collapsed {
    padding-right: 65px;
    transition: 0.3s padding-right ease;
  }

  html:lang(ar) .not-collapsed {
    padding-right: 300px;
    transition: 0.3s padding-right ease;
  }
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(assets/font/Poppins-Regular.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Almarai";
  src: local("Almarai"),
    url(assets/font/Almarai-Regular.ttf) format("truetype");
}

.en {
  font-family: "Poppins-Regular", sans-serif !important;
  direction: ltr;
}

.ar {
  font-family: 'Almarai', sans-serif !important;
  direction: rtl;
}
a:hover {
    text-decoration: none !important;
}
</style>
