import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialogImageModel),callback:function ($$v) {_vm.dialogImageModel=$$v},expression:"dialogImageModel"}},[_c(VCard,[_c(VSpacer),_c(VCardTitle,{staticClass:"justify-content-center;",staticStyle:{"padding":"0px"}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":_vm.image,"lazy-src":_vm.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }