<template>
    <div>
        <base-add-page-layout
            :breadEn="trainerBase.ifEdit ? breadEditEn : breadAddEn"
            :breadAr="trainerBase.ifEdit ? breadEditAr : breadAddAr"
            :cardTitle="trainerBase.ifEdit ? cardTitleEdit : cardTitleAdd"
            :btnTitle="btnTitle"
            listRouteName="trainer"
        >
            <template v-slot:form>
                <trainer-form />
            </template>
        </base-add-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useTrainerStore } from "../store/TrainerStore"
import { mapActions, mapState } from "pinia"
import BaseAddPageLayout from "@/layouts/BaseAddPageLayout.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import TrainerForm from "../components/TrainerForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseAddPageLayout,
        NoInternet,
        TrainerForm,
    },
    data: () => ({
        cardTitleAdd: "trainer.add",
        cardTitleEdit: "trainer.edit",
        btnTitle: "trainer.list",
        breadAddEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Trainer',
                disabled: false,
                href: '/trainer',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/trainer/add',
            },
        ],
        breadAddAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المدربين',
                disabled: false,
                href: '/trainer',
            },
            {
                text: 'اضافة',
                disabled: false,
                href: '/trainer/add',
            }
        ],
        breadEditEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Trainer',
                disabled: false,
                href: '/trainer',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        breadEditAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المدربين',
                disabled: false,
                href: '/trainer',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            }
        ],
    }),
    computed: {
        ...mapState(useTrainerStore, ['trainerBase']),
    },
    methods: {
        ...mapActions(useTrainerStore, ['fetchAllTrainer']),
        onRetry() {
            this.fetchAllTrainer()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
