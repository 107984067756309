import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/category", formData);
    },

    getAll() {
        return Client.get("/category");
    },

    getById(id) {
        return Client.get(`/category/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/category/${id}`, formData)},

    delete(id) {
        return Client.delete(`/category/${id}`);
    },
};