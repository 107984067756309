import Trainer from "@/modules/trainer/pages/ListView.vue"
import AddTrainer from "@/modules/trainer/pages/AddView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
import EditTrainer from "@/modules/trainer/pages/AddView.vue"

export default {
    path: "/trainers",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "trainer",
            component: Trainer,
            meta: { title: "Trainers" }
        },
        {
            path: "/trainers/add",
            name: "add-trainer",
            component: AddTrainer,
            meta: { title: "Trainers" }
        },
        {
            path: "/trainers/edit/:id",
            name: "edit-trainer",
            component: EditTrainer,
            meta: { title: "Trainers" }
        },
    ]
};
