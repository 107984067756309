<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :cardTitle="cardTitle" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!verficationBase.errorFetch">
                    <verfication-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="verficationBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useVerficationStore } from "../store/VerficationStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import VerficationTable from "../components/VerficationTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        VerficationTable,
        NoInternet,
    },
    data: () => ({
        btnTitle: "verfication.add",
        cardTitle: "verfication.list",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Verfication',
                disabled: false,
                href: '/verfication',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'الحجوزات',
                disabled: false,
                href: '/verfication',
            }
        ],
    }),
    computed: {
        ...mapState(useVerficationStore, ['verficationBase']),
    },
    methods: {
        ...mapActions(useVerficationStore, ['fetchAllVerfication']),
        onRetry() {
            this.fetchAllVerfication()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
../store/VerficationStore