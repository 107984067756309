import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/auction", formData);
    },

    getAll() {
        return Client.get("/auction");
    },

    getById(id) {
        return Client.get(`/auction/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/auction/${id}`, formData);
    },

    delete(id) {
        return Client.delete(`/auction/${id}`);
    },

    changeStatus(id, formData) {
        return Client.post(`/auction/change-status/${id}`, formData, {
            params: {_method:"PUT"},
            headers: {
                "content-type": "multipart/form-data",
            }
        });
    },
    getRequests(){
        return Client.get("/request");
    }
};