<template>
    <div>
        <v-text-field
            v-model="horseBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('horse.headers')"
            :items="horseList"
            :search="horseBase.search"
            class="my-table"
            :loading="horseBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.adress')}}:</span>
                    {{ item.adress }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.color')}}:</span>
                    {{ item.color }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.price')}}:</span>
                    {{ item.price }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.birth_date')}}:</span>
                    {{ item.birth_date }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.description')}}:</span>
                    {{ item.description }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.owner')}}:</span>
                    {{ item.owner }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.owner_mobile')}}:</span>
                    {{ item.owner_mobile }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.insurance_value')}}:</span>
                    {{ item.insurance_value }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.category')}}:</span>
                    {{ item.category_name }}
                    <br />
                    <br />
                    <span
                        style="color: var(--v-primary-base); font-weight: bold;"
                    >{{ $t('horse.images')}}:</span>
                    <v-carousel
                        cycle
                        height="180"
                        style="width: 400px;"
                        hide-delimiter-background
                        show-arrows-on-hover
                    >
                        <v-carousel-item v-for="(item, index) in item.image" :key="index">
                            <v-img
                                :src="item"
                                :lazy-src="item"
                                aspect-ratio="1.7"
                                class="grey lighten-2"
                                max-width="500px"
                            >
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-carousel-item>
                    </v-carousel>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="horseBase.dialogDeleteModel"
                    :loading="horseBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <!-- <display-image-dialog :dialog-image.sync="dialogImageModel" :image="image"></display-image-dialog> -->
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            <!-- <template v-slot:[`item.image`]="{ item }">
                <v-img
                    @click="displayImage(item)"
                    aspect-ratio="1"
                    :src="item.image"
                    :lazy-src="item.image"
                    class="grey lighten-2"
                    style="cursor: pointer;"
                    max-width="100"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>-->
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import DisplayImageDialog from "@/components/dialogs/DisplayImageDialog.vue";
import { useHorseStore } from "../store/HorseStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog, DisplayImageDialog },

    data() {
        return {
            expanded: [],
            dialogImageModel: false,
            role: null,
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useHorseStore, ['horseBase', 'horseList']),
        ...mapWritableState(useHorseStore, ['image']),
    },

    methods: {
        ...mapActions(useHorseStore, ['fetchAllHorse', 'fetchAllHorseAdmin', 'deleteHorse', 'changeIsActiveHorse']),

        deleteItem(item) {
            this.horseBase.editedIndex = this.horseList.indexOf(item)
            this.horseBase.delete = item
            this.horseBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.horseBase.loading = true
            this.deleteHorse(this.horseBase.delete.id)
        },

        editItem(item) {
            this.horseBase.ifEdit = true
            this.$router.push("/horse/edit/" + item.id)
        },

        displayImage(item) {
            this.image = item.image
            this.dialogImageModel = true
        },
    },
    mounted() {
        this.role = JSON.parse(localStorage.getItem('user')).role
        if (this.role == 1)
            this.fetchAllHorseAdmin()
        else
            this.fetchAllHorse()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>