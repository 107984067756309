<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :cardTitle="cardTitle" :btnTitle="btnTitle" routeName="add-user">
            <template v-slot:dataTable>
                <div v-if="!userBase.errorFetch">
                    <user-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="userBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useUserStore } from "../store/UserStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import UserTable from "../components/UserTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        UserTable,
        NoInternet,
    },
    data: () => ({
        cardTitle: "user.list",
        btnTitle: "user.add",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'User',
                disabled: false,
                href: '/user',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المستخدمين ',
                disabled: false,
                href: '/user',
            }
        ],
    }),
    computed: {
        ...mapState(useUserStore, ['userBase']),
    },
    methods: {
        ...mapActions(useUserStore, ['fetchAllUser']),
        onRetry() {
            this.fetchAllUser()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
