
<template>
    <div class="dashboard" v-if="role == 1 && !dashboardBase.loadingFetch">
        <!-- header -->
        <div class="container">
            <div class="body row" style="margin-top: 30px; row-gap: 30px;">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color">{{$t('dashboard.users')}}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{counts.users}}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <b-icon icon="person"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color">{{$t('dashboard.trainers')}}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{counts.trainer}}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <b-icon icon="person-check"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color">{{$t('dashboard.auctions')}}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{counts.auctions}}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <b-icon icon="hourglass-split"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color">{{$t('dashboard.stables')}}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{counts.stable}}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <b-icon icon="brightness-alt-high-fill"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="body row" style="margin-top: 30px;row-gap: 30px;">
                <div class="col-lg-7">
                    <div class="card mini-card" id="chart">
                        <div class="card-header">
                            <div
                                class="d-flex justify-content-between align-items-center add"
                            >{{$t('dashboard.auctions')}}</div>
                        </div>
                        <div class="card-body">
                            <apexchart
                                ref="chart_book"
                                type="area"
                                height="250"
                                :options="chartOptions_book"
                                :series="series_book"
                            ></apexchart>
                            <div class="card-footer">{{ $t('dashboard.annual') }}</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="card mini-card" id="chart2" style=" min-height: 445px;">
                        <div class="card-header">
                            <div
                                class="d-flex justify-content-between align-items-center add"
                            >{{$t('dashboard.stables')}}</div>
                        </div>
                        <div class="card-body">
                            <apexchart
                                ref="chart_stable"
                                type="polarArea"
                                :options="chartOptions_stable"
                                :series="series_stable"
                                style="margin-top:20px"
                            ></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="container">
            <div class="body row" style="margin-top: 30px">
                <div class="col-lg-6">
                    <v-card style=" min-height: 392px;">
                        <v-card-title class="my-title" color="primary">
                            {{ $t('dashboard.last_booking') }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-simple-table style="margin: 0px; padding: 0px;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            {{ $t('dashboard.user_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.owner_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.real_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.real_price') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in booking" :key="index">
                                        <td class="text-center">{{ item.client_name }}</td>
                                        <td class="text-center">{{ item.owner }}</td>
                                        <td class="text-center">{{ item.realestat_name }}</td>
                                        <td class="text-center">{{ item.realestat_price }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </div>
                <div class="col-lg-6">
                    <v-card style=" min-height: 392px;">
                        <v-card-title class="my-title">
                            {{ $t('dashboard.top_users') }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-simple-table style="margin: 0px; padding: 0px;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            {{ $t('dashboard.user_image') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.user_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.role') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.real_num') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in top_users" :key="index">
                                        <td class="text-center"
                                            style="display: flex;  justify-content: center; align-items: center;"><img
                                                :src="item['user image']" alt="user" style="width: 35px"></td>
                                        <td class="text-center">{{ item['user name'] }}</td>
                                        <td class="text-center">{{ item['role name'] }}</td>
                                        <td class="text-center">{{ item['realestate num'] }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>

                </div>

            </div>
        </div>-->
    </div>
    <loader v-else style="margin-top: 200px" />
</template>

<script>

import Vue from "vue"
import VueApexCharts from "vue-apexcharts"
Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)
import { useDashboardStore } from "@/modules/dashboard/store/DashboardStore"
import { mapState, mapActions } from "pinia"
import Loader from "@/components/main/Loader.vue"
export default {
    name: 'Dashboard',

    components: {
        apexchart: VueApexCharts,
        Loader
    },

    computed: {
        ...mapState(useDashboardStore, ['dashboardBase', 'counts', 'topHorse', 'topStable', 'stableBooking', 'stableName', 'series_stable', 'series_book'])
    },
    data: () => ({
        role: null,
        data: [],
        users: null,
        realestates: null,
        realestat_booking: null,
        available_realestates: null,
        booking: [],
        top_users: [],
        weakly_booking: [],
        // series_book: [{
        //     name: "STOCK ABC",
        //     data: [1, 13, 54, 20, 7, 66, 34, 46, 23, 54, 65, 48]
        // }],

        // series_book: [1, 13, 54, 20, 7, 66, 34, 46, 23, 54, 65, 48],
        chartOptions_book: {
            chart: {
                height: 350,
                type: "area",
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            colors: ['#5A0072'],
            xaxis: {
                categories: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
            },
        },

        chartOptions_stable: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center'
                }
        },
    }),

    methods: {
        ...mapActions(useDashboardStore, ['getCount', 'getTopHorse', 'getTopStable']),
        getWeaklyBooking() {
            // this.$refs.chart_book.updateSeries([{
            //             data: [1, 13, 54, 20, 7, 66, 34, 46, 23, 54, 65, 48]
            //         }]);
            // this.axios.get(this.$store.state.url + "/api/weekly_booking")
            //     .then(res => {
            //         if (res.data.error == false) {
            //             this.weakly_booking = res.data.data
            //         }
            //         const arr = []
            //         this.weakly_booking.forEach((item, index) => {
            //             arr[item.month - 1] = item.realestate_count
            //         })
            //         for (let i = 0; i < arr.length; i++) {
            //             if (arr[i] === undefined) {
            //                 arr[i] = 0
            //             }
            //         }
            //         this.$refs.chart_book.updateSeries([{
            //             data: [1, 13, 54, 20, 7, 86]
            //         }]);
            //         // console.log(this.weakly_booking)
            //     });
        },
        initStable() {
            // console.log("###################################")
            this.$refs.chart_stable.updateOptions({
                labels: this.stableName
            });
            // console.log(this.stableBooking)
            // console.log(this.stableName)
        },
        initBook() {
            this.$refs.chart_book.updateSeries([{
                data: this.series_book[0].data
            }]);
        }
    },
    async mounted() {
        this.role = JSON.parse(localStorage.getItem('user')).role
        // console.log(this.role)
        this.getCount()
        await this.getTopHorse()
        this.initBook()
        await this.getTopStable()
        this.initStable()

        // this.getTopStable()
        // if (this.topStable.length != 0)
        // this.initStable()
    },
}
</script>

<style>
.dashboard .v-card__title {
    color: var(--v-primary-base);
}

html:lang(en) .dashboard .my-title {
    margin-left: 25px !important;
}

html:lang(ar) .dashboard .my-title {
    margin-right: 25px !important;
}

.dashboard .v-card {
    box-shadow: 0 0 10px #d0cbcb4d !important;
    margin: 25px 0px !important;
    border-radius: 18px !important;
    text-align: justify;
    border: none !important;
    padding: 20px 0px;
}

.dashboard .apexcharts-text {
    display: none;
}

.dashboard .mini-card {
    margin: 0px !important;
}

.dashboard .v-data-table {
    /* box-shadow: 0 0 10px #d0cbcb4d !important; */
    /* border-radius: 18px !important; */
    text-align: justify;
    border: none !important;
}

.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
        .v-data-table__empty-wrapper
    ) {
    background: transparent !important;
}

.dashboard .row-header {
    align-items: center;
    margin-bottom: 20px;
}

.dashboard .card-body {
    padding: 25px 40px !important;
}

.dashboard .bi-person {
    color: #e2aa1e;
    padding: 10px;
    font-size: 50px;
    border-radius: 15px;
    background-color: #e2aa1e31;
}

.dashboard .bi-person-check {
    color: #13436a;
    padding: 10px;
    font-size: 50px;
    border-radius: 15px;
    background-color: rgba(0, 0, 255, 0.068);
}

.dashboard .bi-brightness-alt-high-fill {
    color: rgb(30, 182, 0);
    padding: 10px;
    font-size: 50px;
    border-radius: 15px;
    background-color: rgba(0, 179, 0, 0.089);
}

.dashboard .bi-hourglass-split {
    color: purple;
    padding: 10px;
    font-size: 50px;
    border-radius: 15px;
    background-color: rgba(128, 0, 128, 0.164);
}

.dashboard h5 {
    font-size: 17px !important;
    color: var(--v-primary-base);
}

.dashboard h4 {
    color: var(--v-secondary-base);
}

.dashboard .card-header {
    color: var(--v-primary-base) !important;
}
</style>