<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :btnTitle="btnTitle" :cardTitle="cardTitle" :show.sync="show">
            <template v-slot:dataTable>
                <div v-if="!horseCategoryBase.errorFetch">
                    <horseCategory-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="horseCategoryBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
        <horseCategory-form />
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useHorseCategoryStore } from "../store/HorseCategoryStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import HorseCategoryTable from "../components/HorseCategoryTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import HorseCategoryForm from "../components/HorseCategoryForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        HorseCategoryTable,
        NoInternet,
        HorseCategoryForm,
    },
    data: () => ({
        btnTitle: "horseCategory.add",
        cardTitle: "horseCategory.list",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Horse Category',
                disabled: false,
                href: '/horse-category',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'تصنيفات الاحصنة',
                disabled: false,
                href: '/horse-category',
            }
        ],
    }),
    computed: {
        ...mapState(useHorseCategoryStore, ['horseCategoryBase']),
        ...mapWritableState(useHorseCategoryStore, ['show'])
    },
    methods: {
        ...mapActions(useHorseCategoryStore, ['fetchAllHorseCategory']),
        onRetry() {
            this.fetchAllHorseCategory()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
