<template>
    <v-form
        ref="form"
        v-if="(stableBase.ifEdit && !stableBase.loadingFetchItem) || !stableBase.ifEdit"
    >
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="stableModel.name_en" label="stable.name_en" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="stableModel.name_ar" label="stable.name_ar" />
            </v-col>
            

            <v-col cols="12" sm="12" md="6">
                <textarea-input :text.sync="stableModel.description_en" label="stable.description_en" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <textarea-input :text.sync="stableModel.description_ar" label="stable.description_ar" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <map-input :marker.sync="stableModel.marker" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <select-input
                    :model.sync="stableModel.category_id"
                    label="stable.category"
                    :items="categoryList"
                    item_text="name"
                    item_value="id"
                    :multiple="true"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <image-input
                    :image.sync="stableModel.profile_image"
                    label="image"
                    :ifEdit="stableBase.ifEdit"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-file-input
                    chips
                    multiple
                    counter
                    outlined
                    :reverse="getDirection"
                    v-model="stableModel.images"
                    :rules="[stableBase.ifEdit ? noRule : requiredRule, imageArrayRule]"
                    :label="$t('stable.images')"
                    append-icon="mdi-camera"
                    prepend-icon
                    @change="changeImages"
                ></v-file-input>
                <v-carousel
                    cycle
                    height="200"
                    show-arrows-on-hover
                    style="border-radius: 15px"
                    v-if="displayImages.length != 0"
                >
                    <v-carousel-item v-for="(item, index) in displayImages" :key="index">
                        <v-img
                            :aspect-ratio="16/9"
                            contain
                            max-height="200"
                            :src="item"
                            :lazy-src="item"
                            class="grey lighten-3"
                            style="margin-bottom: 30px;border-radius: 15px"
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
            <h5 style="position: relative; bottom: -20px;padding:0px 15px;">{{$t('stable.details')}}</h5>
            <v-col cols="1">
                <v-btn color="secondary" class="plus-button" @click="add()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="1">
                <v-btn color="secondary" class="plus-button" @click="subtract()">
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-for="(item, index) in stableModel.details" :key="'detail-' + index"  style="margin-top: 20px">
            <v-col cols="12" sm="12" md="6">
                <select-input :model.sync="item.day" label="stable.day" :items="dayList" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <time-input :time.sync="item.open_at" label="stable.open_at" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <time-input :time.sync="item.close_at" label="stable.close_at" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="item.booking_duration" label="stable.booking_duration" />
            </v-col>
        </v-row>
        <v-row>
            <h5 style="position: relative; bottom: -20px;padding:0px 15px;">{{$t('stable.service')}}</h5>
            <v-col cols="1">
                <v-btn color="secondary" class="plus-button" @click="addService()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="1">
                <v-btn color="secondary" class="plus-button" @click="subtractService()">
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-for="(serviceItem, i) in stableModel.services" :key="'service-' + i" style="margin-top: 20px">
            <v-col cols="12" sm="12" md="6">
                <select-input
                    :model.sync="serviceItem.service_id"
                    label="stable.service"
                    :items="serviceList"
                    item_text="name"
                    item_value="id"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="serviceItem.process_time" label="stable.process_time" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <number-input :number.sync="serviceItem.employees_nb" label="stable.employees_nb" />
            </v-col>
        </v-row>

        <!-- submit -->
        <v-row>
            <v-col cols="12">
                <submit-btn @click="submit" :loading="stableBase.loading" />
            </v-col>
        </v-row>
    </v-form>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useStableStore } from "../store/StableStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import MapInput from "@/components/inputs/MapInput.vue"
import { useCategoryStore } from "@/modules/category/store/CategoryStore"
import { useServiceStore } from "@/modules/service/store/ServiceStore"
import TimeInput from '@/components/inputs/TimeInput.vue'
import NumberInput from '@/components/inputs/NumberInput.vue'

export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        TextareaInput,
        SelectInput,
        Loader,
        MapInput,
        TimeInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            showPassword: false,
            genderList: ["male", "female"],
            displayImages: []
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useStableStore, ["stableBase", "stableModel", "dayList"]),
        ...mapState(useCategoryStore, ['categoryList']),
        ...mapState(useServiceStore, ['serviceList']),
    },
    methods: {
        ...mapActions(useStableStore, ['addStable', 'editStable', 'clearStableModel', 'getStableById']),
        ...mapActions(useCategoryStore, ['fetchAllCategory']),
        ...mapActions(useServiceStore, ['fetchAllService']),
        changeImages(event) {
            this.displayImages = []
            if (event.length != 0) {
                event.forEach(item => {
                    if (item != null || item != undefined)
                        this.displayImages.push(URL.createObjectURL(item))
                })
            }
            else {
                this.displayImages = []
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.stableBase.ifEdit
                    ? this.editStable(this.stableModel.id, this.createFormData())
                    : this.addStable(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('name_en', this.stableModel.name_en)
            formData.append('name_ar', this.stableModel.name_ar)
            formData.append('profile_image', this.stableModel.profile_image)
            formData.append('description_en', this.stableModel.description_en)
            formData.append('description_ar', this.stableModel.description_ar)
            formData.append('latitude', this.stableModel.marker.lat)
            formData.append('longitude', this.stableModel.marker.lng)

            this.stableModel.images.forEach((item, index) => {
                formData.append(`images[${index}]`, item)
            })
            this.stableModel.category_id.forEach((item, index) => {
                formData.append(`category_id[${index}]`, item)
            })

            this.stableModel.details.forEach((item, index) => {
                formData.append(`details[${index}][day]`, item.day)
                formData.append(`details[${index}][open_at]`, item.open_at)
                formData.append(`details[${index}][close_at]`, item.close_at)
                formData.append(`details[${index}][booking_duration]`, item.booking_duration)
            })

            this.stableModel.services.forEach((item, index) => {
                formData.append(`services[${index}][service_id]`, item.service_id)
                formData.append(`services[${index}][process_time]`, item.process_time)
                formData.append(`services[${index}][employees_nb]`, item.employees_nb)
            })
            return formData
        },
        initEdit() {
            const id = this.$route.params.id
            this.stableBase.ifEdit = true
            this.getStableById(id)
        },
        initAdd() {
            this.stableBase.ifEdit = false
            this.clearStableModel()
            this.$refs.form.resetValidation()
        },
        add() {
            this.stableModel.details.push({
                day: '',
                open_at: '',
                close_at: '',
                booking_duration: '',
            });
        },
        subtract() {
            if (this.stableModel.details.length > 1)
                this.stableModel.details.pop()
        },
        addService() {
            this.stableModel.services.push({
                service_id: '',
                process_time: '',
                employees_nb: '',
            });
        },
        subtractService() {
            if (this.stableModel.services.length > 1)
                this.stableModel.services.pop()
        }
    },
    mounted() {
        (this.$route.name === "edit-stable" || this.$route.name == "edit-my-stable") ? this.initEdit() : this.initAdd()
        this.fetchAllCategory()
        this.fetchAllService()
    }
};
</script>
<style>
</style>
