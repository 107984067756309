import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import DashboardService from "../services/DashboardService";
export const useDashboardStore = defineStore("dashboardStore", {
    state: () => ({
        dashboardBase: new BaseState(),
        counts: {},
        topHorse: [],
        topStable: [],
        stableBooking: [],
        stableName: [],
        series_stable: [],
        series_book: [{
            name: "Auctions Count",
            data: []
        }],
    }),
    getters: {
    },
    actions: {
        getCount() {
            this.dashboardBase.loadingFetch = true
            DashboardService.getCount().then((res) => {
                this.counts = res.data.data
                this.dashboardBase.loadingFetch = false
            })
                .catch((err) => {
                    this.dashboardBase.loadingFetch = false
                });
        },
        async getTopHorse() {
            this.dashboardBase.loadingFetch = true
            try {
                const res = await DashboardService.getTopHorse();
                this.topHorse = res.data.data;
                this.topHorse.forEach((item) => {
                    this.series_book[0].data[item.month - 1] = item.auction_count
                })
                for (let i = 0; i < this.series_book[0].data.length; i++) {
                    if (this.series_book[0].data[i] === undefined) {
                        this.series_book[0].data[i] = 0
                    }
                }
                // console.log(this.series_book[0].data)
            } catch (err) {
                // Handle error
            } finally {
                this.dashboardBase.loadingFetch = false;
            }
        },
        async getTopStable() {
            this.dashboardBase.loadingFetch = true;
            try {
                const res = await DashboardService.getTopStable();
                this.topStable = res.data.data;
                this.topStable.forEach((item, index) => {
                    this.stableBooking[index] = item.booking_count;
                    this.stableName[index] = item.name;
                });
                this.series_stable = this.stableBooking;
            } catch (err) {
                // Handle error
            } finally {
                this.dashboardBase.loadingFetch = false;
            }
        }
    },

});
