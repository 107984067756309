<template>
    <div>
        <v-text-field
            v-model="stableBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('stable.headers')"
            :items="stableList"
            :search="stableBase.search"
            class="my-table"
            :loading="stableBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="stableBase.dialogDeleteModel"
                    :loading="stableBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <display-image-dialog :dialog-image.sync="dialogImageModel" :image="image"></display-image-dialog>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div
                    class="row justify-content-center"
                    style="top: 15px; position: relative;direction: ltr"
                >
                    <v-switch
                        v-model="item.status"
                        inset
                        color="green"
                        @click="isActiveItem(item)"
                    ></v-switch>
                </div>
            </template>
            <template v-slot:[`item.profile_image`]="{ item }">
                <v-img
                    @click="displayImage(item)"
                    aspect-ratio="1"
                    :src="item.profile_image"
                    :lazy-src="item.profile_image"
                    class="grey lighten-2"
                    style="cursor: pointer;"
                    max-width="100"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <v-btn @click="displayItem(item)" text icon color="indigo">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
                <!-- <v-btn @click="editItem(item)" text icon color="green">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn> -->
            </template>
        </v-data-table>
        <details-dialog :display="display" :dialog-details.sync="dialogDetailsModal" />
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue'
import DisplayImageDialog from "@/components/dialogs/DisplayImageDialog.vue"
import { useStableStore } from "../store/StableStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import { useLangStore } from "@/store/LangStore"
import DetailsDialog from './DetailsDialog.vue'


export default {
    components: { 
        ConfirmDeleteDialog, 
        DisplayImageDialog, 
        DetailsDialog
    },
    data() {
        return {
            dialogImageModel: false,
            dialogDetailsModal: false,
            display: {},
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useStableStore, ['stableBase', 'stableList']),
        ...mapWritableState(useStableStore, ['image']),
    },

    methods: {
        ...mapActions(useStableStore, ['fetchAllStable', 'deleteStable', 'changeStatusStable']),

        deleteItem(item) {
            this.stableBase.editedIndex = this.stableList.indexOf(item)
            this.stableBase.delete = item
            this.stableBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.stableBase.loading = true
            this.deleteStable(this.stableBase.delete.id)
        },

        editItem(item) {
            this.stableBase.ifEdit = true
            this.$router.push("/stable/edit/" + item.id)
        },

        displayImage(item) {
            this.image = item.profile_image
            this.dialogImageModel = true
        },

        isActiveItem(item) {
            const formData = new FormData()
            formData.append('status', +(item.status))
            this.changeStatusStable(item.id, formData)
        },

        displayItem(item) {
            this.display = item
            this.dialogDetailsModal = true
        }

    },
    mounted() {
        this.fetchAllStable()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>