<template>
    <div class="add">
        <breadcrumbs :breadcrumbs="breadEn" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="breadAr" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t( `${cardTitle}`) }}
                <list-btn :title="btnTitle" :listRouteName="listRouteName" />
            </div>
            <div class="card-body">
                <slot name="form"></slot>
            </div>
        </div>
    </div>
</template>


<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import ListBtn from "@/components/buttons/ListBtn.vue"
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"


export default {
    name: "BaseAddPageLayout",
    props: {
        breadEn: Array,
        breadAr: Array,
        cardTitle: String,
        btnTitle: String,
        listRouteName: String
    },
    components: {
        Breadcrumbs,
        ListBtn
    },
    computed: {
        ...mapState(useLangStore, ['lang']),
    },
    
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
