<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :btnTitle="btnTitle" :cardTitle="cardTitle" :show.sync="show">
            <template v-slot:dataTable>
                <div v-if="!categoryBase.errorFetch">
                    <category-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="categoryBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
        <category-form />
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useCategoryStore } from "../store/CategoryStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import CategoryTable from "../components/CategoryTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import CategoryForm from "../components/CategoryForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        CategoryTable,
        NoInternet,
        CategoryForm,
    },
    data: () => ({
        btnTitle: "category.add",
        cardTitle: "category.list",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Category',
                disabled: false,
                href: '/category',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'التصنيفات',
                disabled: false,
                href: '/category',
            }
        ],
    }),
    computed: {
        ...mapState(useCategoryStore, ['categoryBase']),
        ...mapWritableState(useCategoryStore, ['show'])
    },
    methods: {
        ...mapActions(useCategoryStore, ['fetchAllCategory']),
        onRetry() {
            this.fetchAllCategory()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
