<template>
    <div>
        <base-add-page-layout
            :breadEn="userBase.ifEdit ? breadEditEn : breadAddEn"
            :breadAr="userBase.ifEdit ? breadEditAr : breadAddAr"
            :cardTitle="userBase.ifEdit ? cardTitleEdit : cardTitleAdd"
            :btnTitle="btnTitle"
            listRouteName="user"
        >
            <template v-slot:form>
                <user-form />
            </template>
        </base-add-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useUserStore } from "../store/UserStore"
import { mapState } from "pinia"
import BaseAddPageLayout from "@/layouts/BaseAddPageLayout.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import UserForm from "../components/UserForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseAddPageLayout,
        NoInternet,
        UserForm,
    },
    data: () => ({
        cardTitleAdd: "user.add",
        cardTitleEdit: "user.edit",
        btnTitle: "user.list",
        breadAddEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'User',
                disabled: false,
                href: '/user',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/user/add',
            },
        ],
        breadAddAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المستخدمين',
                disabled: false,
                href: '/user',
            },
            {
                text: 'اضافة',
                disabled: false,
                href: '/user/add',
            }
        ],
        breadEditEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'User',
                disabled: false,
                href: '/user',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        breadEditAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المستخدمين',
                disabled: false,
                href: '/user',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            }
        ],
    }),
    computed: {
        ...mapState(useUserStore, ['userBase']),
    },
    methods: {
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
