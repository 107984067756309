<template>
    <div>
        <base-list-page-layout
            :breadEn="breadEn"
            :breadAr="breadAr"
            :cardTitle="cardTitle"
            :btnTitle="btnTitle"
            routeName="add-horse"
        >
            <template v-slot:dataTable>
                <div v-if="!horseBase.errorFetch">
                    <horse-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="horseBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useHorseStore } from "../store/HorseStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import HorseTable from "../components/HorseTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        HorseTable,
        NoInternet,
    },
    data: () => ({
        cardTitle: "horse.list",
        btnTitle: "horse.add",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Horse',
                disabled: false,
                href: '/horse',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'الاحصنة ',
                disabled: false,
                href: '/horse',
            }
        ],
        role: null,
    }),
    computed: {
        ...mapState(useHorseStore, ['horseBase']),
    },
    methods: {
        ...mapActions(useHorseStore, ['fetchAllHorse', 'fetchAllHorseAdmin']),
        onRetry() {
            if (this.role == 1)
                this.fetchAllHorseAdmin()
            else
                this.fetchAllHorse()
        }
    },
    mounted() {
        this.role = JSON.parse(localStorage.getItem('user')).role
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
