<template>
    <v-dialog v-model="dialogImageModel" max-width="600px">
        <v-card>
            <v-spacer></v-spacer>
            <v-card-title class="justify-content-center;" style="padding: 0px">
                <v-img :src="image" :lazy-src="image" class="grey lighten-2">
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-card-title>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DisplayImageDialog",
    props: {
        dialogImage: {
            type: Boolean,
            required: true
        },
        image: {
            type: String,
            required: true
        }
    },
    computed: {
        dialogImageModel: {
            get() {
                return this.dialogImage
            },
            set(value) {
                this.$emit('update:dialogImage', value)
            }
        }
    },
}
</script>

<style>
</style>