import Horse from "@/modules/horse/pages/ListView.vue"
import AddHorse from "@/modules/horse/pages/AddView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
import EditHorse from "@/modules/horse/pages/AddView.vue"
export default {
    path: "/horse",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "horse",
            component: Horse,
            meta: { title: "Horses" }
        },
        {
            path: "/horse/add",
            name: "add-horse",
            component: AddHorse,
            meta: { title: "Horses" }
        },
        {
            path: "/horse/edit/:id",
            name: "edit-horse",
            component: EditHorse,
            meta: { title: "Horses" }
        },
    ]
};
