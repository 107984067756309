<template>
    <div>
        <v-text-field
            v-model="auctionBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('auction.headers')"
            :items="requestList"
            :search="auctionBase.search"
            class="my-table"
            :loading="auctionBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
        
        <template v-slot:top>
                <change-status-dialog :change-status.sync="changeStatusModal" :item="changeStatusItem"/>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-btn color="warning" outlined v-if="item.status == 'Pending'" @click="changeStatus(item)">
                    <v-icon style="margin: 0 5px">
                        mdi-pencil
                    </v-icon>
                    {{item.status}}
                </v-btn>
                <v-btn color="success" outlined v-if="item.status == 'Accepted'" @click="changeStatus(item)">
                    <v-icon style="margin: 0 5px">
                        mdi-pencil
                    </v-icon>
                    {{item.status}}
                </v-btn>
                <v-btn color="grey" outlined v-if="item.status == 'Cancelled'" @click="changeStatus(item)">
                    <v-icon style="margin: 0 5px">
                        mdi-pencil
                    </v-icon>
                    {{item.status}}
                </v-btn>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <v-btn @click="displayItem(item)" text icon color="indigo">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <div>
                    {{ item.date.slice(0, 10) }}
                </div>
            </template>
        </v-data-table>
        
        <details-dialog :display="display" :dialog-details.sync="dialogDetailsModal" />
    </div>
</template>
<script>
import { useAuctionStore } from "../store/AuctionStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import ChangeStatusDialog from "./ChangeStatusDialog.vue"
import DetailsDialog from './DetailsDialog.vue';

export default {
    components: {
        ChangeStatusDialog,
        DetailsDialog
    },
    data() {
        return {
            dialogDetailsModal: false,
            display: {},
            changeStatusModal: false,
            changeStatusItem: {}
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useAuctionStore, ['auctionBase', 'requestList']),
    },

    methods: {
        ...mapActions(useAuctionStore, ['fetchAllAuction', 'deleteAuction', 'getRequests']),


        changeStatus(item) {
            this.changeStatusModal = true
            this.changeStatusItem = item
        },

        displayItem(item) {
            this.display = item
            this.dialogDetailsModal = true
        }

    },
    mounted() {
        this.getRequests()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>