import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/horse-category", formData);
    },

    getAll() {
        return Client.get("/horse-category");
    },

    getById(id) {
        return Client.get(`/horse-category/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/horse-category/${id}`, formData)},

    delete(id) {
        return Client.delete(`/horse-category/${id}`);
    },
};