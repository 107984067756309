import Stable from "@/modules/stable/pages/ListView.vue"
import AddStable from "@/modules/stable/pages/AddView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
import EditStable from "@/modules/stable/pages/AddView.vue"
import MyStable from "@/modules/stable/pages/MyStable.vue"
import EditMyStable from "@/modules/stable/pages/AddView.vue"
export default {
    path: "/stable",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "stable",
            component: Stable,
            meta: { title: "Stables" }
        },
        {
            path: "/stable/add",
            name: "add-stable",
            component: AddStable,
            meta: { title: "Add Stable" }
        },
        {
            path: "/stable/edit/:id",
            name: "edit-stable",
            component: EditStable,
            meta: { title: "Edit Stable" }
        },
        {
            path: "/my-stable/edit/:id",
            name: "edit-my-stable",
            component: EditMyStable,
            meta: { title: "My Stable" }
        },
        {
            path: "/my-stable",
            name: "my-stable",
            component: MyStable,
            meta: { title: "My Stable" }
        }
    ]
};
