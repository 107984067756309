<template>
    <div class="list">
        <breadcrumbs :breadcrumbs="breadEn" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="breadAr" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t( `${cardTitle}`) }}
                <add-btn :title="btnTitle" :routeName="routeName" :hideAdd="hideAdd" :show.sync="showUpdate"/>
                <edit-btn :title="btnTitle" :routeName="routeName" v-if="showEdit"/>
            </div>
            <div class="card-body">
                <slot name="dataTable"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import AddBtn from "@/components/buttons/AddBtn.vue"
import EditBtn from "@/components/buttons/EditBtn.vue"
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"

export default {
    name: "BaseListPageLayout",
    props: {
        breadEn: Array,
        breadAr: Array,
        routeName: String,
        hideAdd: Boolean,
        showEdit: Boolean,
        show: Boolean,
        cardTitle: String,
        btnTitle: String,

    },
    components: {
        Breadcrumbs,
        AddBtn,
        EditBtn,
    },
    computed: {
        ...mapState(useLangStore, ['lang']),
        showUpdate:{
            get(){
                return this.show
            },
            set(value){
                this.$emit('update:show', value)
            }
        }
    },
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
