<template>
    <div>
        <base-add-page-layout
            :breadEn="breadEn"
            :breadAr="breadAr"
            :cardTitle="cardTitleAdd"
            :btnTitle="btnTitle"
            listRouteName="edit-my-profile"
        >
            <template v-slot:form >
                <div v-if="!userBase.errorFetch">
                    <display-profile />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="userBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-add-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useUserStore } from "../store/UserStore"
import { mapState } from "pinia"
import BaseAddPageLayout from "@/layouts/BaseAddPageLayout.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import DisplayProfile from "../components/DisplayProfile.vue"

export default {
    components: {
        Breadcrumbs,
        BaseAddPageLayout,
        NoInternet,
        DisplayProfile,
    },
    data: () => ({
        cardTitleAdd: "user.profile",
        cardTitleEdit: "user.edit_profile",
        btnTitle: "user.edit_profile",
        breadEn: [
            {
                text: 'My Profile',
                disabled: false,
                href: '/my-profile',
            },
        ],
        breadAr: [
            {
                text: 'حسابي الشخصي',
                disabled: false,
                href: '/my-profile',
            },
        ],
    }),
    computed: {
        ...mapState(useUserStore, ['userBase']),
    },
    methods: {
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
