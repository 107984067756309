import Category from "@/modules/horse_category/pages/ListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/horse-category",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "horse-category",
            component: Category,
            meta: { title: "Horse Categories" }
        }
    ]
};
