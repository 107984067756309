import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import BookingService from "../services/BookingService";

export const useBookingStore = defineStore("bookingStore", {
    state: () => ({
        bookingBase: new BaseState(),
        bookingList: [],
    }),
    getters: {
    },
    actions: {
        fetchAllBooking() {
            this.bookingBase.loadingFetch = true
            BookingService.getAll().then((res) => {
                // console.log(res.data.data)
                this.bookingList = res.data.data.reverse()
                this.bookingBase.loadingFetch = false
                this.bookingBase.errorFetch = false
            })
                .catch((err) => {
                    this.bookingBase.loadingFetch = false
                    this.bookingBase.errorFetch = true
                });
        },
        // deleteBooking(id) {
        //     this.bookingBase.loading = true
        //     BookingService.delete(id).then((res) => {
        //         this.bookingBase.loading = false
        //         this.bookingList.splice(this.bookingBase.editedIndex, 1)
        //         this.bookingBase.dialogDeleteModel = false
        //     })
        //         .catch((err) => {
        //             this.bookingBase.loading = false
        //             this.bookingBase.error = true
        //         })
        // },
    },
});
