import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/trainer", formData);
    },

    getAll() {
        return Client.get("/trainer");
    },

    // getById(id) {
    //     return Client.get(`/trainer/${id}`);
    // },

    // edit(id, formData) {
    //     return Client.post(`/trainer/${id}`, formData);
    // },

    delete(id) {
        return Client.delete(`/trainer/${id}`);
    },

};