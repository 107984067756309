<template>
    <div>
        <v-file-input
            outlined
            :reverse="getDirection"
            v-model="newImage"
            :rules="[ifEdit ? noRule : requiredRule, imageRule]"
            :label="$t(`${label}`)"
            append-icon="mdi-camera"
            prepend-icon
            @change="changeImage"
            hint="The supported image formats are (jpeg, jpg, png, svg, avif)"
            :persistent-hint="true"
        ></v-file-input>
        <v-img
            :aspect-ratio="16/9"
            contain
            max-height="200"
            :src="displayImage"
            :lazy-src="displayImage"
            class="grey lighten-3"
            style="margin-bottom: 30px"
            v-if="displayImage != ''"
        >
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </template>
        </v-img>

        <!-- <v-img
            :src="displayImage"
            :lazy-src="displayImage"
            max-height="200"
            style="margin-bottom: 30px"
            contain
            v-if="displayImage != ''"
            class="grey lighten-2"
        >
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </template>
        </v-img>-->
    </div>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "ImageInput",
    mixins: [FormValidationRulesMixin],
    props: {
        label: {
            type: String,
            required: true
        },
        image: {
            required: true
        },
        ifEdit: {
            type: Boolean,
        }
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newImage: {
            get() {
                if (typeof (this.image) != "string")
                    return this.image
            },
            set(value) {
                // console.log("form component: ")
                // console.log(value)
                this.$emit('update:image', value)
            }
        },
        displayImage: {
            get() {
                if (typeof (this.image) == "string")
                    return this.image
                else if (typeof (this.image) != "string" && this.image != null)
                    return URL.createObjectURL(this.image)
                else
                    return ''
            },
            set() { }
        },
    },
    methods: {
        changeImage(event) {
            if (event != null || event != undefined)
                this.displayImage = URL.createObjectURL(event);
            else {
                this.displayImage = ""
            }
        },
    },
}
</script>

<style>
</style>