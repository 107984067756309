import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.changeStatusModal),callback:function ($$v) {_vm.changeStatusModal=$$v},expression:"changeStatusModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-content-start",staticStyle:{"padding":"20px 25px 0px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('change_status')))]),_c(VDivider),_c(VCardText,{staticStyle:{"padding-top":"20px !important"}},[_c('select-input',{attrs:{"items":_vm.statusList,"model":_vm.status,"label":"auction.status"},on:{"update:model":function($event){_vm.status=$event}}})],1),_c(VDivider),_c(VCardActions,{staticStyle:{"padding-bottom":"20px"}},[_c('submit-btn',{attrs:{"loading":_vm.auctionBase.loading},on:{"click":_vm.change}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }