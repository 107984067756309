import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/stable", formData);
    },

    getAll() {
        return Client.get("/stable");
    },

    getById(id) {
        return Client.get(`/stable/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/stable/${id}`, formData);
    },

    delete(id) {
        return Client.delete(`/stable/${id}`);
    },

    changeStatus(id, formData) {
        return Client.post(`/stable/change-status/${id}`, formData, {
            params: {_method:"PUT"},
            headers: {
                "content-type": "multipart/form-data",
            }
        });
    },
    getMyStable(){
        return Client.get("/my-stable");
    }
};