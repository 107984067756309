import { defineStore } from "pinia";


export const useSidebarStore = defineStore("sidebarStore", {
    state: () => ({
        ifCollapsed: false,
    }),
    getters: {
        getIfCollapsed() {
            return this.ifCollapsed
        },
    },
    actions: {
        toggleCollapse(collapsed) {
            this.ifCollapsed = collapsed
        },
    },
});
