<template>
    <div>
        <sidebar-menu
            :rtl="getDirection"
            :menu="lang == 'en' ? menuEn : menuAr"
            widthCollapsed="65px"
            width="300px"
            @toggle-collapse="onToggleCollapse"
        >
            <template v-slot:header>
                <div class="row logo">
                    <div calss="col-lg-3">
                        <img src="@/assets/img/logo.svg" alt="Logo" />
                    </div>
                    <div
                        class="col-lg-9 name-email"
                        style=" font-size: 25px;color: var(--v-secondary-base); font-weight: bold;margin-top: 10px"
                    >7RM</div>
                </div>
            </template>
        </sidebar-menu>
    </div>
</template>

<script>
import { useSidebarStore } from '@/store/SidebarStore'
import { useLangStore } from "@/store/LangStore"
import { useAuthStore } from "@/modules/auth/store/AuthStore"
import { mapState, mapActions } from "pinia"

export default {
    name: 'Sidebar',
    data: () => ({
        name: null,
        email: null,
        menuEn: [],
        menuAr: []
    }),
    computed: {
        ...mapState(useLangStore, ['lang', 'getDirection']),
        ...mapState(useAuthStore, ['user'])
    },
    methods: {
        ...mapActions(useSidebarStore, ['toggleCollapse']),

        onToggleCollapse(collapsed) {
            this.toggleCollapse(collapsed)
        },
    },

    mounted() {
        if (this.user.role == 1){
            this.menuEn = [
                {
                    href: "/",
                    title: "Dashboard",
                    icon: "fa fa-dashboard"
                },
                {
                    href: "/user",
                    title: "User",
                    icon: "fas fa-user-friends"
                },
                {
                    href: "/category",
                    title: "Category",
                    icon: "fas fa-table"
                },
                {
                    href: "/service",
                    title: "Service",
                    icon: "fa fa-cogs"
                },
                {
                    href: "/horse-category",
                    title: "Horse Category",
                    icon: "fas fa-table"
                },
                {
                    href: "/horse",
                    title: "Horse",
                    icon: "fas fa-horse"
                },
                {
                    href: "/stable",
                    title: "Stable",
                    icon: "fas fa-spa"
                },
                {
                    href: "/verfication",
                    title: "Stable Verfication",
                    icon: "fas fa-shield-alt"
                },
                {
                    title: "Auction",
                    icon: "fa fa-gavel",
                    child: [
                        {
                            href: "/auction",
                            title: "Display"
                        },
                        {
                            href: "/auction/requests",
                            title: "Requests"
                        }
                    ]
                },
            ]
            this.menuAr = [
                {
                    href: "/",
                    title: "لوحة التحكم",
                    icon: "fa fa-dashboard"
                },
                {
                    href: "/user",
                    title: "المستخدمين",
                    icon: "fas fa-user-friends"
                },
                {
                    href: "/category",
                    title: "التصنيفات",
                    icon: "fas fa-table"
                },
                {
                    href: "/service",
                    title: "الخدمات",
                    icon: "fa fa-cogs"
                },
                {
                    href: "/horse-category",
                    title: "تصنيفات الاحصنة",
                    icon: "fas fa-table"
                },
                {
                    href: "/horse",
                    title: "الاحصنة",
                    icon: "fas fa-horse"
                },
                {
                    href: "/stable",
                    title: "الاسطبلات",
                    icon: "fas fa-spa"
                },
                {
                    title: "المزادات",
                    icon: "fa fa-gavel",
                    child: [
                        {
                            href: "/auction",
                            title: "عرض"
                        },
                        {
                            href: "/auction/requests",
                            title: "طلبات المزايدة"
                        }
                    ]
                },
            ]
        }
        else if (this.user.role == 4){
            this.menuEn = [
                {
                    href: "/my-stable",
                    title: "Stable",
                    icon: "fas fa-spa"
                },
                {
                    href: "/trainers",
                    title: "Trainers",
                    icon: "fas fa-user-friends"
                },
                {
                    href: "/horse",
                    title: "Horse",
                    icon: "fas fa-horse"
                },
                {
                    href: "/booking",
                    title: "Booking",
                    icon: "far fa-calendar-alt"
                },
                {
                    title: "Auction",
                    icon: "fa fa-gavel",
                    href: "/auction",
                },
            ]
            this.menuAr = [
                {
                    href: "/my-stable",
                    title: "اسطبلي",
                    icon: "fas fa-spa"
                },
                {
                    href: "/trainers",
                    title: "المدربين",
                    icon: "fas fa-user-friends"
                },
                {
                    href: "/horse",
                    title: "الاحصنة",
                    icon: "fas fa-horse"
                },
                {
                    href: "/booking",
                    title: "الحجوزات",
                    icon: "far fa-calendar-alt"
                },
                {
                    title: "المزادات",
                    icon: "fa fa-gavel",
                    href: "/auction",
                },
            ]
        }
    },
}

</script>


<style lang="scss">
html:lang(en) .v-sidebar-menu.vsm_rtl .vsm--icon {
    margin-left: 10px !important;
    margin-right: 0px !important;
}

html:lang(ar) .v-sidebar-menu.vsm_rtl .vsm--icon {
    margin-right: 8px !important;
    margin-left: 10px !important;
}

html:lang(en) .logo {
    flex-wrap: nowrap !important;
    margin-left: 10px !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

html:lang(ar) .logo {
    flex-wrap: nowrap !important;
    margin-right: 10px !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.logo img {
    width: 50px;
}

@media (max-width: 767px) {
    .v-sidebar-menu {
        box-shadow: 5px 0 10px #a7a6a6;
    }
}

.v-sidebar-menu .bi-person-fill {
    font-size: 50px;
    transition: all 0.3s;
}

.v-sidebar-menu .header {
    padding: 20px;
    align-items: center;
    color: white;
}

.v-sidebar-menu .name,
.v-sidebar-menu .email {
    margin-bottom: 0px !important;
}

.v-sidebar-menu .email {
    font-size: 13px;
}

.v-sidebar-menu .name-email {
    transition: all 0.1s;
    transition-delay: 5s;
}

.v-sidebar-menu.vsm_collapsed {
    .name-email {
        display: none;
        transition: all 0.1s;
        transition-delay: 5s;
    }

    .bi-person-fill {
        font-size: 45px;
        transition: all 0.3s;
    }

    .logo img {
        width: 48px;
    }

    html:lang(en) .logo {
        margin-left: 5px !important;
    }

    html:lang(ar) .logo {
        margin-right: 5px !important;
    }
}

.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
    background-color: transparent !important;
}

.v-sidebar-menu .vsm--scroll-wrapper {
    margin-right: 0px !important;
}

html:lang(ar) .v-sidebar-menu .vsm--scroll-wrapper {
    margin-left: 0px !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_expanded
    .vsm--item_open
    .vsm--link_level-1 {
    background-color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
    background-color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--mobile-bg {
    background-color: #fff !important;
    // border: 1px solid var(--v-primary-base) !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link {
    color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
    color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1,
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
    color: #fff !important;
    background-color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link_active .vsm--icon {
    color: #fff !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_collapsed
    .vsm--link_level-1:hover
    .vsm--icon {
    color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.v-sidebar-menu.vsm_white-theme.vsm_collapsed
    .vsm--link_level-1.vsm--link_hover
    .vsm--icon {
    color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_rtl .vsm--link_level-1.vsm--link_active,
.v-sidebar-menu.vsm_white-theme.vsm_rtl
    .vsm--link_level-1.vsm--link_exact-active {
    box-shadow: -3px 0px 0px 0px var(--v-secondary-base) inset !important;
}

html:lang(en) .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
html:lang(en) .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    box-shadow: 3px 0px 0px 0px var(--v-secondary-base) inset !important;
}

html:lang(ar) .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
html:lang(ar) .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    box-shadow: -3px 0px 0px 0px var(--v-secondary-base) inset !important;
}

.v-sidebar-menu .vsm--mobile-bg {
    background-color: var(--v-secondary-base) !important;
}

.v-sidebar-menu {
    background-color: var(--v-primary-base) !important;
}

.v-sidebar-menu .vsm--dropdown .vsm--list {
    background-color: var(--v-primary-base) !important;
}

.v-sidebar-menu .vsm--toggle-btn {
    background-color: var(--v-primary-base) !important;
}
</style>
