<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :cardTitle="cardTitle" :btnTitle="btnTitle" routeName="add-stable" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!stableBase.errorFetch">
                    <stable-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="stableBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useStableStore } from "../store/StableStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import StableTable from "../components/StableTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        StableTable,
        NoInternet,
    },
    data: () => ({
        cardTitle: "stable.list",
        btnTitle: "stable.add",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Stable',
                disabled: false,
                href: '/stable',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'الاسطبلات ',
                disabled: false,
                href: '/stable',
            }
        ],
    }),
    computed: {
        ...mapState(useStableStore, ['stableBase']),
    },
    methods: {
        ...mapActions(useStableStore, ['fetchAllStable']),
        onRetry() {
            this.fetchAllStable()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
