<template>
    <v-dialog v-model="dialogDetailsModal" max-width="700px">
        <v-card>
            <v-spacer></v-spacer>
            <v-card-title
                class="justify-content-start"
                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;"
            >{{$t('details')}}</v-card-title>
            <v-card-text style="padding-bottom: 40px;">
                <v-simple-table style=" padding: 0px 0px 20px; text-align: justify;">
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <th>{{ $t('stable.name') }}</th>
                                <td>{{ display.name }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t('stable.description') }}</th>
                                <td>{{ display.description }}</td>
                            </tr>
                            <tr v-if="display.category.length != 0">
                                <th>{{ $t('stable.category') }}</th>
                                <td>
                                    <ul>
                                        <li
                                            v-for="(item, index) in display.category"
                                            :key="index"
                                        >{{ item.name }}</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr v-if="display.service.length != 0">
                                <th>{{ $t('stable.services') }}</th>
                                <td>
                                    <ul>
                                        <li
                                            v-for="(item, index) in display.service"
                                            :key="index" >
                                            <v-row>
                                                <!-- <span style="margin: 15px;">{{$t('stable.service')}}:</span> -->
                                                <p style="margin:5px 15px;"
                                                >{{item.service[0].name}}</p>
                                            </v-row>
                                        </li>
                                        <!-- <li>
                                            <v-row>
                                                <span
                                                    style="margin: 15px;"
                                                >{{$t('stable.process_time')}}:</span>
                                                <p
                                                    class="text-muted"
                                                >{{display.service.process_time}}</p>
                                            </v-row>
                                        </li>
                                        <li>
                                            <v-row>
                                                <span
                                                    style="margin: 0 15px;"
                                                >{{$t('stable.employees_nb')}}:</span>
                                                <p
                                                    class="text-muted"
                                                >{{display.service.employees_nb}}</p>
                                            </v-row>
                                        </li> -->
                                        
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('stable.location') }}</th>
                                <td>
                                    <GmapMap
                                        :center="{ lat: marker.lat, lng: marker.lng }"
                                        :zoom="12"
                                        map-type-id="terrain"
                                        style="height: 200px; width: 400px"
                                    >
                                        <GmapMarker :position="marker" />
                                    </GmapMap>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('stable.profile_image') }}</th>
                                <td>
                                    <v-img
                                        aspect-ratio="1.7"
                                        :src="display.profile_image"
                                        :lazy-src="display.profile_image"
                                        class="grey lighten-2"
                                        max-width="400px"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                            </tr>
                            <tr v-if="display.images.length != 0">
                                <th>{{ $t('stable.images') }}</th>
                                <td>
                                    <v-carousel
                                        cycle
                                        height="200"
                                        style="width: 400px;"
                                        hide-delimiter-background
                                        show-arrows-on-hover
                                    >
                                        <v-carousel-item
                                            v-for="(item, index) in display.images"
                                            :key="index"
                                        >
                                            <v-img
                                                :src="item.image"
                                                :lazy-src="item.image"
                                                aspect-ratio="1.7"
                                                class="grey lighten-2"
                                                max-width="400px"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-carousel-item>
                                    </v-carousel>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DetailsDialog",
    props: {
        dialogDetails: {
            type: Boolean,
            required: true
        },
        display: {
            type: Object,
            required: true
        }
    },

    computed: {
        dialogDetailsModal: {
            get() {
                return this.dialogDetails
            },
            set(value) {
                this.$emit('update:dialogDetails', value)
            }
        },
        marker() {
            return {
                lat: parseFloat(this.display.latitude),
                lng: parseFloat(this.display.longitude)
            }
        }
    },
}
</script>

<style>
</style>