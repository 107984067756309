import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import StableService from "../services/StableService";
import router from "@/router"
export const useStableStore = defineStore("stableStore", {
    state: () => ({
        stableBase: new BaseState(),
        stableModel: {
            id: '',
            name_en: '',
            name_ar: '',
            description_en: '',
            description_ar: '',
            profile_image: '',
            latitude: '',
            longitude: '',
            marker: null,
            images: [],
            details: [
                {
                    day: '',
                    open_at: '',
                    close_at: '',
                    booking_duration: '',
                }
            ],
            services: [
                {
                    service_id: '',
                    process_time: '',
                    employees_nb: '',
                }
            ],
            category_id: [],
        },
        stableList: [],
        image: '',
        dayList: ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        myStable: [],
        marker: null,
    }),
    getters: {
    },
    actions: {
        clearStableModel() {
            this.stableModel.id = ''
            this.stableModel.name_en = ""
            this.stableModel.name_ar = ""
            this.stableModel.description_en = ""
            this.stableModel.description_ar = ""
            this.stableModel.profile_image = ''
            this.stableModel.latitude = ''
            this.stableModel.longitude = ''
            this.stableModel.images = []
            this.stableModel.details = [
                {
                    day: '',
                    open_at: '',
                    close_at: '',
                    booking_duration: '',
                }
            ]
            this.stableModel.services = [
                {
                    service_id: '',
                    process_time: '',
                    employees_nb: ''
                }
            ]
            this.stableModel.category_id = []
        },
        addStable(formData) {
            this.stableBase.loading = true;
            StableService.add(formData).then((res) => {
                this.stableBase.loading = false
                this.clearStableModel()
                router.push('/my-stable')
            })
                .catch((err) => {
                    this.stableBase.loading = false
                    this.stableBase.error = true
                });
        },
        fetchAllStable() {
            this.stableBase.loadingFetch = true
            StableService.getAll().then((res) => {
                this.stableList = res.data.data.reverse()
                this.stableBase.loadingFetch = false
                this.stableBase.errorFetch = false
            })
                .catch((err) => {
                    this.stableBase.loadingFetch = false
                    this.stableBase.errorFetch = true
                });
        },
        deleteStable(id) {
            this.stableBase.loading = true
            StableService.delete(id).then((res) => {
                this.stableBase.loading = false
                this.stableList.splice(this.stableBase.editedIndex, 1)
                this.stableBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.stableBase.loading = false
                    this.stableBase.error = true
                })
        },
        getStableById(id) {
            this.stableBase.loadingFetchItem = true
            StableService.getById(id).then((res) => {
                this.stableBase.loadingFetchItem = false
                this.stableModel.id = res.data.data.id
                this.stableModel.name_en = res.data.data.name.en
                this.stableModel.name_ar = res.data.data.name.ar
                this.stableModel.description_en = res.data.data.description.en
                this.stableModel.description_ar = res.data.data.description.ar
                this.stableModel.profile_image = res.data.data.profile_image
                this.stableModel.marker = {
                    lat: Number(res.data.data.latitude),
                    lng: Number(res.data.data.longitude)
                }
                res.data.data.images.forEach(item => {
                    this.stableModel.images.push(item.image)
                })
                this.stableModel.details = []
                res.data.data.availableBooking.forEach(item =>{
                    this.stableModel.details.push({
                        day: item.day,
                        open_at: item.open_at,
                        close_at: item.close_at,
                        booking_duration: item.booking_duration,
                    })
                })
                this.stableModel.services = []
                res.data.data.service.forEach(item => {
                    this.stableModel.services.push({
                        service_id: item.service[0].id,
                        process_time: item.process_time,
                        employees_nb: item.employees_nb,
                    })
                })
                // this.stableModel.services = res.data.data.service
                res.data.data.category.forEach(item => {
                    this.stableModel.category_id.push(item.id)
                });
            })
                .catch((err) => {
                    this.stableBase.loadingFetchItem = false
                    this.stableBase.error = true
                })
        },
        editStable(id, formData) {
            this.stableBase.loading = true;
            StableService.edit(id, formData).then((res) => {
                this.stableBase.loading = false
                this.stableBase.ifEdit = false
                router.push('/stable')
                this.clearStableModel()
            })
                .catch((err) => {
                    this.stableBase.loading = false
                    this.stableBase.error = true
                });
        },
        changeStatusStable(id, formData) {
            StableService.changeStatus(id, formData).then((res) => {
                this.fetchAllStable()
            })
                .catch((err) => {
                    this.stableBase.error = true
                });
        },
        fetchMyStable(){
            this.stableBase.loadingFetchItem = true
            StableService.getMyStable().then((res) => {
                this.myStable = res.data.data
                // console.log(res.data.data.latitude)
                this.marker = {
                    lat: Number(res.data.data.latitude),
                    lng: Number(res.data.data.longitude)
                }
                // console.log(this.marker)
                this.stableModel.id = res.data.data.id
                this.stableModel.name_en = res.data.data.name_en
                this.stableModel.description_en = res.data.data.description_en
                this.stableModel.profile_image = res.data.data.profile_image
                this.stableModel.latitude = res.data.data.latitude
                this.stableModel.longitude = res.data.data.longitude
                this.stableModel.images = res.data.data.images
                this.stableModel.details = res.data.data.details
                this.stableModel.services = res.data.data.services
                this.stableModel.category_id = res.data.data.category_id
                this.stableBase.loadingFetchItem = false
                this.stableBase.errorFetch = false
            })
                .catch((err) => {
                    this.stableBase.loadingFetchItem = false
                    this.stableBase.errorFetch = true
                });
        }
    },

});
