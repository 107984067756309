<template>
    <div>
        <base-add-page-layout
            :breadEn="auctionBase.ifEdit ? breadEditEn : breadAddEn"
            :breadAr="auctionBase.ifEdit ? breadEditAr : breadAddAr"
            :cardTitle="auctionBase.ifEdit ? cardTitleEdit : cardTitleAdd"
            :btnTitle="btnTitle"
            listRouteName="auction"
        >
            <template v-slot:form>
                <auction-form />
            </template>
        </base-add-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useAuctionStore } from "../store/AuctionStore"
import { mapActions, mapState } from "pinia"
import BaseAddPageLayout from "@/layouts/BaseAddPageLayout.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import AuctionForm from "../components/AuctionForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseAddPageLayout,
        NoInternet,
        AuctionForm,
    },
    data: () => ({
        cardTitleAdd: "auction.add",
        cardTitleEdit: "auction.edit",
        btnTitle: "auction.list",
        breadAddEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Auction',
                disabled: false,
                href: '/auction',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/auction/add',
            },
        ],
        breadAddAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المزادات',
                disabled: false,
                href: '/auction',
            },
            {
                text: 'اضافة',
                disabled: false,
                href: '/auction/add',
            }
        ],
        breadEditEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Auction',
                disabled: false,
                href: '/auction',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        breadEditAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المزادات',
                disabled: false,
                href: '/auction',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            }
        ],
    }),
    computed: {
        ...mapState(useAuctionStore, ['auctionBase']),
    },
    methods: {
        ...mapActions(useAuctionStore, ['fetchAllAuction']),
        onRetry() {
            this.fetchAllAuction()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
