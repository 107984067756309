import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/user", formData);
    },

    getAll() {
        return Client.get("/user");
    },

    getById(id) {
        return Client.get(`/user/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/user/${id}`, formData);
    },

    delete(id) {
        return Client.delete(`/user/${id}`);
    },

    changeIsActive(id, formData) {
        return Client.post(`/user/change-status/${id}`, formData, {
            params: {_method:"PUT"},
            headers: {
                "content-type": "multipart/form-data",
            }
        });
    },
    getRole(){
        return Client.get("/user/roles");
    }
};