import Category from "@/modules/category/pages/ListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/category",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "category",
            component: Category,
            meta: { title: "Categories" }
        }
    ]
};
