import { defineStore } from "pinia"
import { BaseState } from "@/store/BaseState"
import AuctionService from "../services/AuctionService"
import router from "@/router"
export const useAuctionStore = defineStore("auctionStore", {
    state: () => ({
        auctionBase: new BaseState(),
        auctionModel: {
            id: '',
            title_en: '',
            title_ar: '',
            description_en: '',
            description_ar: '',
            date: '',
            latitude: '',
            longitude: '',
            marker: null,
            horse_id: [],
            location_name: '',
            insurance_value: '',
            start_time: '',
            end_time: '',
            auction_subscription: '',
            user_id: '',
        },
        auctionList: [],
        myAuction: [],
        marker: null,
        requestList: []
    }),
    actions: {
        clearAuctionModel() {
            this.auctionModel.id = ''
            this.auctionModel.title_en = ""
            this.auctionModel.description_en = ""
            this.auctionModel.title_ar = ""
            this.auctionModel.description_ar = ""
            this.auctionModel.date = ''
            this.auctionModel.latitude = ''
            this.auctionModel.longitude = ''
            this.auctionModel.horse_id = []
            this.auctionModel.location_name = ''
            this.auctionModel.insurance_value = ''
            this.auctionModel.start_time = ''
            this.auctionModel.end_time = ''
            this.auctionModel.auction_subscription = ''
        },
        addAuction(formData) {
            this.auctionBase.loading = true;
            AuctionService.add(formData).then((res) => {
                this.auctionBase.loading = false
                this.clearAuctionModel()
                router.push('/auction')
            })
                .catch((err) => {
                    this.auctionBase.loading = false
                    this.auctionBase.error = true
                });
        },
        fetchAllAuction() {
            this.auctionBase.loadingFetch = true
            AuctionService.getAll().then((res) => {
                this.auctionList = res.data.data.reverse()
                this.auctionBase.loadingFetch = false
                this.auctionBase.errorFetch = false
            })
                .catch((err) => {
                    this.auctionBase.loadingFetch = false
                    this.auctionBase.errorFetch = true
                });
        },
        deleteAuction(id) {
            this.auctionBase.loading = true
            AuctionService.delete(id).then((res) => {
                this.auctionBase.loading = false
                this.auctionList.splice(this.auctionBase.editedIndex, 1)
                this.auctionBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.auctionBase.loading = false
                    this.auctionBase.error = true
                })
        },
        getAuctionById(id) {
            this.auctionBase.loadingFetchItem = true
            AuctionService.getById(id).then((res) => {
                this.auctionBase.loadingFetchItem = false
                this.auctionModel.id = res.data.data.id
                this.auctionModel.title_en = res.data.data.title.en
                this.auctionModel.description_en = res.data.data.description.en
                this.auctionModel.title_ar = res.data.data.title.ar
                this.auctionModel.description_ar = res.data.data.description.ar
                this.auctionModel.date = res.data.data.date.slice(0, 10)
                this.auctionModel.latitude = res.data.data.latitude
                this.auctionModel.longitude = res.data.data.longitude
                res.data.data.auction_horses.forEach(item =>{
                    this.auctionModel.horse_id.push(item.id)
                })
                this.auctionModel.location_name = res.data.data.location_name
                this.auctionModel.insurance_value = res.data.data.insurance_value
                this.auctionModel.start_time = res.data.data.start_time.slice(11, 16)
                this.auctionModel.end_time = res.data.data.end_time.slice(11, 16)
                this.auctionModel.auction_subscription = res.data.data.auction_subscription
                this.auctionModel.marker = {
                    lat: Number(res.data.data.latitude),
                    lng: Number(res.data.data.longitude)
                }
            })
                .catch((err) => {
                    this.auctionBase.loadingFetchItem = false
                    this.auctionBase.error = true
                })
        },
        editAuction(id, formData) {
            this.auctionBase.loading = true;
            AuctionService.edit(id, formData).then((res) => {
                this.auctionBase.loading = false
                this.auctionBase.ifEdit = false
                router.push('/auction')
                this.clearAuctionModel()
            })
                .catch((err) => {
                    this.auctionBase.loading = false
                    this.auctionBase.error = true
                });
        },
        changeStatusAuction(id, formData) {
            AuctionService.changeStatus(id, formData).then((res) => {
                this.fetchAllAuction()
                this.getRequests()
            })
                .catch((err) => {
                    this.auctionBase.error = true
                });
        },
        getRequests(){
            this.auctionBase.loadingFetch = true
            AuctionService.getRequests().then((res) => {
                this.requestList = res.data.data.reverse()
                this.auctionBase.loadingFetch = false
                this.auctionBase.errorFetch = false
            })
                .catch((err) => {
                    this.auctionBase.loadingFetch = false
                    this.auctionBase.errorFetch = true
                });
        }
    },
});
