<template>
    <div class="breadcrumbs">
        <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
</template>

<script>

export default {
    name: 'Breadcrumbs',
    props:["breadcrumbs"],
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    }
};
</script>

<style lang="scss">
.v-breadcrumbs {
    background-color: white;
    margin: 40px 50px;
    border-radius: 18px;
    box-shadow: 0 0 10px #d0cbcb4d;
    text-align: justify;
    padding-right: 24px;
}
</style>