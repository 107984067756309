<template>
    <div>
        <v-btn v-if="(routeName)" :to="{name:`${routeName}`}" color="primary" outlined>
            {{ $t(`${title}`) }}
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "EditBtn",
    props: ['title', 'routeName'],
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
    }
};
</script>
