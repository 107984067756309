<template>
    <v-form ref="form" v-if="(userBase.ifEdit && !userBase.loadingFetchItem) || !userBase.ifEdit">
        <v-row>
            <v-col cols="2">
                <v-img :src="userModel.image" :lazy-src="userModel.image" class="grey lighten-2" width="120" style="border-radius: 50%">
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
            <v-col cols="5">
                <text-input :text.sync="userModel.first_name" label="user.first_name" :readonly="true" />
                <v-text-field
                    type="email"
                    prepend-inner-icon="mdi-email"
                    outlined
                    :reverse="getDirection"
                    v-model="userModel.email"
                    :rules="[requiredRule, emailRule]"
                    :label="$t('user.email')"
                    validate-on-blur
                    :readonly="true" 
                ></v-text-field>
                <date-input
                    :date.sync="userModel.date_of_birth"
                    label="user.date_of_birth"
                    :readonly="true" 
                />
                
            </v-col>
            <v-col cols="5">
                <text-input :text.sync="userModel.last_name" label="user.last_name"  :readonly="true" />
                <select-input
                    :model.sync="userModel.gender"
                    label="user.gender"
                    :items="genderList"
                    :readonly="true" 
                />
                <number-input :number.sync="userModel.mobile" label="user.mobile" :readonly="true"  />
                <!-- <select-input
                    :model.sync="userModel.role_id"
                    label="user.role"
                    :items="roleList"
                    item_text="name"
                    item_value="id"
                    :readonly="true" 
                /> -->
            </v-col>
        </v-row>
    </v-form>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useUserStore } from "../store/UserStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        TextareaInput,
        SelectInput,
        Loader,
        DateInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            showPassword: false,
            genderList: ["male", "female"]
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useUserStore, ["userBase", "userModel", "roleList"]),
    },
    methods: {
        ...mapActions(useUserStore, ['clearUserModel', 'getUserById', 'getRole']),
        initEdit() {
            const id = JSON.parse(localStorage.getItem('user')).id
            // this.userBase.ifEdit = true
            this.getUserById(id)
        },
    },
    mounted() {
        this.initEdit()
        this.getRole()
        this.userBase.ifEdit = true
    }
};
</script>
<style>
</style>
