<template>
    <div>
        <v-text-field
            v-model="horseCategoryBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('horseCategory.headers')"
            :items="horseCategoryList"
            :search="horseCategoryBase.search"
            class="my-table"
            :loading="horseCategoryBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('horseCategory.description')}}: </span> {{ item.description }}
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="horseCategoryBase.dialogDeleteModel"
                    :loading="horseCategoryBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.image`]="{ item }">
                <v-img
                    @click="displayImage(item)"
                    aspect-ratio="1"
                    :src="item.image"
                    :lazy-src="item.image"
                    class="grey lighten-2"
                    style="cursor: pointer;"
                    max-width="100"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useHorseCategoryStore } from "../store/HorseCategoryStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            expanded: []
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useHorseCategoryStore, ['horseCategoryBase', 'horseCategoryList']),
        ...mapWritableState(useHorseCategoryStore, ["show"]),
    },

    methods: {
        ...mapActions(useHorseCategoryStore, ['fetchAllHorseCategory', 'deleteHorseCategory', 'getHorseCategoryById']),

        deleteItem(item) {
            this.horseCategoryBase.editedIndex = this.horseCategoryList.indexOf(item)
            this.horseCategoryBase.delete = item
            this.horseCategoryBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.horseCategoryBase.loading = true
            this.deleteHorseCategory(this.horseCategoryBase.delete.id)
        },

        editItem(item) {
            this.horseCategoryBase.ifEdit = true
            this.getHorseCategoryById(item.id)
            this.show = true
        },
    },
    mounted() {
        this.fetchAllHorseCategory()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>