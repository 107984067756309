<template>
    <div v-if="!stableBase.loadingFetchItem">
        <div v-if="myStable.length != 0">
            <v-form>
                <v-row>
                    <v-col cols="6">
                        <text-input :text.sync="myStable.name" label="stable.name" :readonly="true"/>
                    </v-col>
                    <v-col cols="6">
                        <select-input :readonly="true"
                            :model.sync="myStable.category"
                            label="stable.category"
                            :items="categoryList"
                            item_text="name"
                            item_value="id"
                            :multiple="true"
                        />
                    </v-col>
                    <v-col cols="6">
                        <textarea-input
                        :readonly="true"
                            :text.sync="myStable.description"
                            label="stable.description"
                        />
                    </v-col>
                    <v-col cols="6">
                        <GmapMap
                            :center="{ lat: marker.lat, lng: marker.lng }"
                            :zoom="12"
                            map-type-id="terrain"
                            style="height: 150px"
                        >
                            <GmapMarker :position="marker" />
                        </GmapMap>
                    </v-col>
                    <v-col cols="6">
                        <p>Profile Image</p>
                        <v-img
                            :src="myStable.profile_image"
                            :lazy-src="myStable.profile_image"
                            aspect-ratio="1.7"
                            class="grey lighten-2"
                            max-width="500px"
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>
                    <v-col cols="6">
                        <p>Images</p>
                        <v-carousel
                            cycle
                            height="280"
                            hide-delimiter-background
                            show-arrows-on-hover
                        >
                            <v-carousel-item v-for="(item, index) in myStable.images" :key="index">
                                <v-img
                                    :src="item.image"
                                    :lazy-src="item.image"
                                    aspect-ratio="1.7"
                                    class="grey lighten-2"
                                    max-width="500px"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <h5
                        style="position: relative; padding:0px 15px;"
                    >{{$t('stable.details')}}</h5>
                    
                </v-row>
                <v-row
                    v-for="(item, index) in myStable.availableBooking"
                    :key="'detail-' + index"
                    style="margin-top: 20px"
                >
                    <v-col cols="6">
                        <select-input :model.sync="item.day" label="stable.day" :items="dayList" :readonly="true" />
                    </v-col>
                    <v-col cols="6">
                        <time-input :time.sync="item.open_at" label="stable.open_at" :readonly="true"/>
                    </v-col>
                    <v-col cols="6">
                        <time-input :time.sync="item.close_at" label="stable.close_at" :readonly="true" />
                    </v-col>
                    <v-col cols="6">
                        <text-input
                            :text.sync="item.booking_duration"
                            label="stable.booking_duration"
                            :readonly="true"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <h5
                        style="position: relative;padding:0px 15px;"
                    >{{$t('stable.service')}}</h5>
                </v-row>

                <v-row
                    v-for="(serviceItem, i) in myStable.service"
                    :key="'service-' + i"
                    style="margin-top: 20px"
                >
                    <v-col cols="6">
                        <select-input
                            :model.sync="serviceItem.service[0].id"
                            label="stable.service"
                            :items="serviceList"
                            item_text="name"
                            item_value="id"
                            :readonly="true"
                        />
                    </v-col>
                    <v-col cols="6">
                        <text-input
                            :text.sync="serviceItem.process_time"
                            label="stable.process_time" :readonly="true"
                        />
                    </v-col>
                    <v-col cols="6">
                        <number-input
                            :number.sync="serviceItem.employees_nb"
                            label="stable.employees_nb" :readonly="true"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div v-else>
            <no-data @create="onCreate"></no-data>
        </div>
    </div>
    <loader v-else />
</template>

<script>
import { useStableStore } from "../store/StableStore"
import { mapState, mapActions } from "pinia"
import { useLangStore } from "@/store/LangStore"
import NoData from "@/components/main/NoData.vue"
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import MapInput from "@/components/inputs/MapInput.vue"
import TimeInput from '@/components/inputs/TimeInput.vue'
import NumberInput from '@/components/inputs/NumberInput.vue'
import { useCategoryStore } from "@/modules/category/store/CategoryStore"
import { useServiceStore } from "@/modules/service/store/ServiceStore"

export default {
    components: {
        NoData,
        ImageInput,
        TextInput,
        TextareaInput,
        Loader,
        SelectInput,
        MapInput,
        TimeInput,
        NumberInput
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useStableStore, ['stableBase', 'myStable', 'myStable', "dayList", 'marker']),
        ...mapState(useCategoryStore, ['categoryList']),
        ...mapState(useServiceStore, ['serviceList']),
    },

    methods: {
        ...mapActions(useStableStore, ['fetchMyStable']),
        ...mapActions(useCategoryStore, ['fetchAllCategory']),
        ...mapActions(useServiceStore, ['fetchAllService']),
        onCreate() {
            this.$router.push('/stable/add')
        }
    },

    mounted() {
        this.fetchMyStable()
        this.fetchAllCategory()
        this.fetchAllService()
    }
}
</script>

<style>
</style>