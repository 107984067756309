<template>
    <div v-if="!hideAdd">
        <v-btn v-if="(routeName)" :to="{name:`${routeName}`}" color="primary" outlined>
            {{ $t(`${title}`) }}
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <div v-else>
            <v-btn @click="showUpdated = true" color="primary" outlined>
                {{ $t(`${title}`) }}
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
// import { useDialogStore } from "@/store/DialogStore"
export default {
    name: "AddBtn",
    props: ['title', 'routeName', 'hideAdd', "show"],
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        // ...mapWritableState(useDialogStore, ["show"]),
        showUpdated: {
            get(){
                return this.show
            },
            set(value){
                this.$emit('update:show', value)
            }
        }
    },
    methods: {
    }
};
</script>
