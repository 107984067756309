import Auction from "@/modules/auction/pages/ListView.vue"
import AddAuction from "@/modules/auction/pages/AddView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
import EditAuction from "@/modules/auction/pages/AddView.vue"
import Request from "@/modules/auction/pages/Request.vue"
export default {
    path: "/auction",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "auction",
            component: Auction,
            meta: { title: "Auctions" }
        },
        {
            path: "/auction/add",
            name: "add-auction",
            component: AddAuction,
            meta: { title: "Auctions" }
        },
        {
            path: "/auction/edit/:id",
            name: "edit-auction",
            component: EditAuction,
            meta: { title: "Auctions" }
        },
        {
            path: "/auction/requests",
            name: "auction-requests",
            component: Request,
            meta: { title: "Auction Requests" }
        },
    ]
};
