import Booking from "@/modules/booking/pages/ListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/booking",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "booking",
            component: Booking,
            meta: { title: "Booking" }
        }
    ]
};
