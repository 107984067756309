import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from "@/modules/dashboard/pages/Dashboard.vue"
import NotFound from "@/modules/NotFound.vue"

import { useAuthStore } from "@/modules/auth/store/AuthStore"
import pinia from "../store"

Vue.use(VueRouter)


/**
 * @desc this function is pull all routes from modules , the .routes extension is required required in route files
 **/
const routesFiles = require.context("../", true, /\.routes.js$/);
const routesModules = routesFiles.keys().reduce((routesModules, routePath) => {
  const value = routesFiles(routePath);
  routesModules.push(value.default);
  return routesModules;
}, []);



const routes = [
  //modules routes
  ...routesModules,
  //fallback route
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: { title: "Page not found" },
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { title: "Dashboard" },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
})



//change the title of the browser tab according to route's meta title
const DEFAULT_TITLE = process.env.VUE_APP_PROJECT_NAME;
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    if (to.meta.title) document.title = to.meta.title;
    else document.title = DEFAULT_TITLE;
  });
});




router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('user')

  // console.log(user)
  if (user !== null) {
    const requiredPermission = localStorage.getItem('permission')

    if (to.path != "/login") {

      if (requiredPermission.includes(to.meta.title))
        next();
      else
        next("/not-found");
    }
    else
      next("/");
  } else if (to.path !== "/login") {
    next("/login");
  } else {
    next();
  }
});



//routes that need an authenticated or authorized user
// const authStore = useAuthStore(pinia);

// router.beforeEach((to, from, next) => {
//   if (authStore.isAuthenticated) {
//     if (to.path == "/login") {
//       next("/");
//     } else {
//       if (to.meta.requiredPermission) {
//         //case the route requires a permission
//         var requiredPermission = to.meta.requiredPermission;
//         var permissions = authStore.permissions;
//         if (
//           //case the user doesn't have the req permission redirect to 404
//           !permissions ||
//           !permissions.includes(
//             requiredPermission.replace(/'/g, "").replace(/"/g, "")
//           )
//         ) {
//           next("/not-found");
//         } else next();
//       } else next();
//     }
//   } else if (to.path !== "/login") {
//     next("/login");
//   } else {
//     next();
//   }
// });


router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
