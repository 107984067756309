import Client from "@/clients/AxiosClient";

export default {
    getCount() {
        return Client.get("/show/counts");
    },

    getTopHorse() {
        return Client.get(`/top-horse`);
    },

    getTopStable() {
        return Client.get(`/top-stable`);
    },
};