<template>
    <form-dialog
        :title="!horseCategoryBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        width="800px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="form" v-if="!horseCategoryBase.loadingFetchItem"> 
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <text-input :text.sync="horseCategoryModel.name_en" label="horseCategory.name_en" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <text-input :text.sync="horseCategoryModel.name_ar" label="horseCategory.name_ar" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <textarea-input :text.sync="horseCategoryModel.description_en" label="horseCategory.description_en" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <textarea-input :text.sync="horseCategoryModel.description_ar" label="horseCategory.description_ar" />
                    </v-col>
                </v-row>
            </v-form>
            <loader v-else />
        </template>
        <template v-slot:actions>
            <cancel-btn @click="cancel" />
            <submit-btn @click="submit" :loading="horseCategoryBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useHorseCategoryStore } from "../store/HorseCategoryStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        CancelBtn,
        TextareaInput,
        Loader
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "horseCategory.add",
            titleEdit: "horseCategory.edit",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useHorseCategoryStore, ["horseCategoryBase", "horseCategoryModel"]),
        ...mapWritableState(useHorseCategoryStore, ["show"]),

    },
    methods: {
        ...mapActions(useHorseCategoryStore, ['addHorseCategory', 'editHorseCategory', 'clearHorseCategoryModel']),
        submit() {
            if (this.$refs.form.validate()) {
                this.horseCategoryBase.ifEdit
                    ? this.editHorseCategory(this.horseCategoryModel.id, this.createFormData())
                    : this.addHorseCategory(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('name_en', this.horseCategoryModel.name_en)
            formData.append('description_en', this.horseCategoryModel.description_en)
            formData.append('name_ar', this.horseCategoryModel.name_ar)
            formData.append('description_ar', this.horseCategoryModel.description_ar)
            if(this.ifImageUpdated() )
                formData.append('image', this.horseCategoryModel.image)
            return formData
        },
        ifImageUpdated() {
            return (typeof (this.horseCategoryModel.image) != "string") && (this.horseCategoryModel.image != null) ? true : false
        },
        cancel() {
            this.show = false
            this.horseCategoryBase.ifEdit = false
            this.clearHorseCategoryModel()
            this.$refs.form.resetValidation()
        }
    }
};
</script>
