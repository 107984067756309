<template>
    <form-dialog
        :title="!serviceBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        width="800px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="form" v-if="!serviceBase.loadingFetchItem">
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <text-input :text.sync="serviceModel.name_en" label="service.name_en" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <text-input :text.sync="serviceModel.name_ar" label="service.name_ar" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <number-input :number.sync="serviceModel.price" label="service.price" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <image-input
                            :image.sync="serviceModel.image"
                            label="image"
                            :ifEdit="serviceBase.ifEdit"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <loader v-else />
        </template>
        <template v-slot:actions>
            <cancel-btn @click="cancel" />
            <submit-btn @click="submit" :loading="serviceBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useServiceStore } from "../store/ServiceStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        CancelBtn,
        TextareaInput,
        NumberInput,
        Loader
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "service.add",
            titleEdit: "service.edit",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useServiceStore, ["serviceBase", "serviceModel"]),
        ...mapWritableState(useServiceStore, ["show"]),
    },
    methods: {
        ...mapActions(useServiceStore, ['addService', 'editService', 'clearServiceModel']),
        submit() {
            if (this.$refs.form.validate()) {
                this.serviceBase.ifEdit
                    ? this.editService(this.serviceModel.id, this.createFormData())
                    : this.addService(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('name_en', this.serviceModel.name_en)
            formData.append('name_ar', this.serviceModel.name_ar)
            formData.append('price', this.serviceModel.price)
            if (!this.serviceBase.ifEdit) {
                formData.append('is_top_service', 1)
                formData.append('is_active', 1)
            }
            if (this.ifImageUpdated())
                formData.append('image_path', this.serviceModel.image)
            return formData
        },
        ifImageUpdated() {
            return (typeof (this.serviceModel.image) != "string") && (this.serviceModel.image != null) ? true : false
        },
        cancel() {
            this.show = false
            this.serviceBase.ifEdit = false
            this.clearServiceModel()
            this.$refs.form.resetValidation()
        }
    }
};
</script>
