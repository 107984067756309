<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :cardTitle="cardTitle" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!auctionBase.errorFetch">
                    <request-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="auctionBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useAuctionStore } from "../store/AuctionStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import RequestTable from "../components/RequestTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        RequestTable,
        NoInternet,
    },
    data: () => ({
        cardTitle: "auction.request",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Auction',
                disabled: false,
                href: '/auction',
            },
            {
                text: 'Requests',
                disabled: false,
                href: '/auction/requests',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المزادات',
                disabled: false,
                href: '/auction',
            },
            {
                text: 'طلبات المزايدة',
                disabled: false,
                href: '/auction/requests',
            }
        ],
    }),
    computed: {
        ...mapState(useAuctionStore, ['auctionBase']),
    },
    methods: {
        ...mapActions(useAuctionStore, ['getRequests']),
        onRetry() {
            this.getRequests()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
