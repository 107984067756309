<template>
    <div>
        <v-text-field
            v-model="auctionBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('auction.headers')"
            :items="auctionList"
            :search="auctionBase.search"
            class="my-table"
            :loading="auctionBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="auctionBase.dialogDeleteModel"
                    :loading="auctionBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <change-status-dialog :change-status.sync="changeStatusModal" :item="changeStatusItem"/>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div v-if="role == 1">
                    <v-btn color="warning" outlined v-if="item.status == 'Pending'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        {{item.status}}
                    </v-btn>
                    <v-btn color="success" outlined v-if="item.status == 'Accepted'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        {{item.status}}
                    </v-btn>
                    <v-btn color="grey" outlined v-if="item.status == 'Cancelled'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        {{item.status}}
                    </v-btn>
                </div>
                <div v-else>
                    <v-chip color="warning" outlined v-if="item.status == 'Pending'">
                        {{item.status}}
                    </v-chip>
                    <v-chip color="success" outlined v-if="item.status == 'Accepted'">
                        {{item.status}}
                    </v-chip>
                    <v-chip color="grey" outlined v-if="item.status == 'Cancelled'">
                        {{item.status}}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <v-btn @click="deleteItem(item)" text icon color="error">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn @click="editItem(item)" text icon color="green">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="displayItem(item)" text icon color="indigo">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <div>
                    {{ item.date.slice(0, 10) }}
                </div>
            </template>
        </v-data-table>
        
        <details-dialog :display="display" :dialog-details.sync="dialogDetailsModal" />
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useAuctionStore } from "../store/AuctionStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import ChangeStatusDialog from "./ChangeStatusDialog.vue"
import DetailsDialog from './DetailsDialog.vue';

export default {
    components: {
        ConfirmDeleteDialog,
        ChangeStatusDialog,
        DetailsDialog
    },
    data() {
        return {
            dialogDetailsModal: false,
            display: {},
            changeStatusModal: false,
            changeStatusItem: {},
            role: null
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useAuctionStore, ['auctionBase', 'auctionList']),
    },

    methods: {
        ...mapActions(useAuctionStore, ['fetchAllAuction', 'deleteAuction', 'changeStatusAuction']),

        deleteItem(item) {
            this.auctionBase.editedIndex = this.auctionList.indexOf(item)
            this.auctionBase.delete = item
            this.auctionBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.auctionBase.loading = true
            this.deleteAuction(this.auctionBase.delete.id)
        },

        editItem(item) {
            this.$router.push("/auction/edit/" + item.id)
        },


        changeStatus(item) {
            this.changeStatusModal = true
            this.changeStatusItem = item
        },

        displayItem(item) {
            this.display = item
            this.dialogDetailsModal = true
        }

    },
    mounted() {
        this.fetchAllAuction()
        this.role = JSON.parse(localStorage.getItem('user')).role
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);

</style>