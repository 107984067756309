<template>
    <div>
        <v-text-field
            v-model="serviceBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('service.headers')"
            :items="serviceList"
            :search="serviceBase.search"
            class="my-table"
            :loading="serviceBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="serviceBase.dialogDeleteModel"
                    :loading="serviceBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <display-image-dialog :dialog-image.sync="dialogImageModel" :image="image"></display-image-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
                <div
                    class="row justify-content-center"
                    style="top: 15px; position: relative;direction: ltr"
                >
                    <v-switch
                        v-model="item.is_active"
                        inset
                        color="green"
                        @click="isActiveItem(item)"
                    ></v-switch>
                </div>
            </template>
            <template v-slot:[`item.is_top_service`]="{ item }">
                <div
                    class="row justify-content-center"
                    style="top: 15px; position: relative;direction: ltr"
                >
                    <v-switch
                        v-model="item.is_top_service"
                        inset
                        color="green"
                        @click="isTopServiceItem(item)"
                    ></v-switch>
                </div>
            </template>
            <template v-slot:[`item.image`]="{ item }">
                <v-img
                    @click="displayImage(item)"
                    aspect-ratio="1"
                    :src="item.image"
                    :lazy-src="item.image"
                    class="grey lighten-2"
                    style="cursor: pointer;"
                    max-width="100"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import DisplayImageDialog from "@/components/dialogs/DisplayImageDialog.vue";
import { useServiceStore } from "../store/ServiceStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog, DisplayImageDialog },

    data() {
        return {
            dialogImageModel: false,
            expanded: []
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useServiceStore, ['serviceBase', 'serviceList']),
        ...mapWritableState(useServiceStore, ['image', "show"]),
    },

    methods: {
        ...mapActions(useServiceStore, ['fetchAllService', 'deleteService', 'getServiceById', 'changeIsActiveService', 'changeIsTopService']),

        deleteItem(item) {
            this.serviceBase.editedIndex = this.serviceList.indexOf(item)
            this.serviceBase.delete = item
            this.serviceBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.serviceBase.loading = true
            this.deleteService(this.serviceBase.delete.id)
        },

        displayImage(item) {
            this.image = item.image
            this.dialogImageModel = true
        },

        editItem(item) {
            this.serviceBase.ifEdit = true
            this.getServiceById(item.id)
            this.show = true
        },

        isActiveItem(item){
            const formData = new FormData()
            formData.append('is_active',+(item.is_active))
            this.changeIsActiveService(item.id, formData)
        },

        isTopServiceItem(item){
            const formData = new FormData()
            formData.append('is_top_service',+(item.is_top_service))
            this.changeIsTopService(item.id, formData)
        }
    },
    mounted() {
        this.fetchAllService()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>