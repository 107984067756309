<template>
    <v-form
        ref="form"
        v-if="(trainerBase.ifEdit && !trainerBase.loadingFetchItem) || !trainerBase.ifEdit"
    >
        <v-row>
            <v-col cols="6">
                <text-input :text.sync="trainerModel.first_name" label="trainer.first_name" />
            </v-col>
            <v-col cols="6">
                <text-input :text.sync="trainerModel.last_name" label="trainer.last_name" />
            </v-col>
            <v-col cols="6">
                <select-input
                    :model.sync="trainerModel.gender"
                    label="trainer.gender"
                    :items="genderList"
                />
            </v-col>

            <v-col cols="6">
                <date-input
                    :date.sync="trainerModel.date_of_birth"
                    label="trainer.date_of_birth"
                    :allowedDates="allowedDates"
                />
            </v-col>

            <v-col cols="6">
                <v-text-field
                    type="email"
                    prepend-inner-icon="mdi-email"
                    outlined
                    :reverse="getDirection"
                    v-model="trainerModel.email"
                    :rules="[requiredRule, emailRule]"
                    :label="$t('trainer.email')"
                    validate-on-blur
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-key"
                    :reverse="getDirection"
                    v-model="trainerModel.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[requiredRule, passwordRule]"
                    :label="$t('trainer.password')"
                    validate-on-blur
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <image-input
                    :image.sync="trainerModel.image"
                    label="image"
                    :ifEdit="trainerModel.ifEdit"
                />
            </v-col>
            <v-col cols="6">
                <number-input :number.sync="trainerModel.mobile" label="trainer.mobile" />
            </v-col>
            <v-col cols="12">
                <submit-btn @click="submit" :loading="trainerBase.loading" />
            </v-col>
        </v-row>
    </v-form>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useTrainerStore } from "../store/TrainerStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from '@/components/inputs/NumberInput.vue'
export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        TextareaInput,
        SelectInput,
        Loader,
        DateInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            showPassword: false,
            genderList: ["male", "female"]
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useTrainerStore, ["trainerBase", "trainerModel"]),
    },
    methods: {
        allowedDates: val => val <= new Date().toJSON().slice(0, 10),

        ...mapActions(useTrainerStore, ['addTrainer', 'clearTrainerModel']),
        submit() {
            if (this.$refs.form.validate()) {
                this.trainerBase.ifEdit
                    ? this.editTrainer(this.trainerModel.id, this.createFormData())
                    : this.addTrainer(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('first_name', this.trainerModel.first_name)
            formData.append('last_name', this.trainerModel.last_name)
            formData.append('email', this.trainerModel.email)
            formData.append('password', this.trainerModel.password)
            formData.append('gender', this.trainerModel.gender)
            formData.append('date_of_birth', this.trainerModel.date_of_birth)
            formData.append('mobile', this.trainerModel.mobile)
            formData.append('stable_id', JSON.parse(localStorage.getItem('user')).stable_id)
            if (this.ifImageUpdated())
                formData.append('user_image', this.trainerModel.image)
            return formData
        },
        ifImageUpdated() {
            return (typeof (this.trainerModel.image) != "string") && (this.trainerModel.image != null) ? true : false
        },
        initEdit() {
            const id = this.$route.params.id
            this.trainerBase.ifEdit = true
            this.getTrainerById(id)
        },
        initAdd() {
            this.trainerBase.ifEdit = false
            this.clearTrainerModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.$route.name === "edit-trainer" ? this.initEdit() : this.initAdd()
    }
};
</script>
<style>
</style>
