<template>
    <v-dialog v-model="changeStatusModal" max-width="500px">
        <v-card>
            <v-card-title
                class="justify-content-start"
                style="padding: 20px 25px 0px;font-weight: bold;"
            >{{$t('change_status')}}</v-card-title>
            <v-divider />
            <v-card-text style="padding-top: 20px !important;">
                <select-input :items="statusList" :model.sync="status" label="auction.status" />
            </v-card-text>
            <v-divider />
            <v-card-actions style="padding-bottom: 20px;">
                <submit-btn @click="change" :loading="auctionBase.loading" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectInput from '@/components/inputs/SelectInput.vue'
import { useAuctionStore } from "../store/AuctionStore"
import { mapState, mapActions } from "pinia"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'

export default {
    name: "DetailsDialog",
    components: {
        SelectInput,
        SubmitBtn
    },
    data() {
        return {
            newStatus: '',
            statusList: ['Accepted', 'Pending', 'Cancelled']
        };
    },
    props: {
        changeStatus: {
            type: Boolean,
            required: true
        },
        item: {
            required: true,
            type: Object
        }
    },

    computed: {
        ...mapState(useAuctionStore, ["auctionBase"]),
        changeStatusModal: {
            get() {
                return this.changeStatus
            },
            set(value) {
                this.$emit('update:changeStatus', value)
            }
        },
        status:{
            get() {
                return this.item.status
            },
            set(value) {
                this.newStatus = value
            }
        }
    },
    methods: {
        ...mapActions(useAuctionStore, ['changeStatusAuction']),
        change() {
            const formData = new FormData()
            console.log(this.newStatus)
            formData.append('status', this.newStatus)
            this.changeStatusAuction(this.item.id, formData)
            this.changeStatusModal = false
        }
    },
    mounted(){
        
    }
}
</script>

<style>
</style>