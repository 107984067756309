import Login from "../pages/Login.vue"

export default {
    path: "/login",
    component: Login,

    children: [
        {
            path: "",
            name: "login",
            meta: { title: "Login" }
        },
    ]
};
