import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"700px"},model:{value:(_vm.dialogDetailsModal),callback:function ($$v) {_vm.dialogDetailsModal=$$v},expression:"dialogDetailsModal"}},[_c(VCard,[_c(VSpacer),_c(VCardTitle,{staticClass:"justify-content-start",staticStyle:{"border-bottom":"1px solid #aeaeae","padding-top":"30px","font-weight":"bold","margin-bottom":"40px"}},[_vm._v(_vm._s(_vm.$t('details')))]),_c(VCardText,{staticStyle:{"padding-bottom":"40px"}},[_c(VSimpleTable,{staticStyle:{"padding":"0px 0px 20px","text-align":"justify"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('auction.description')))]),_c('td',[_vm._v(_vm._s(_vm.display.description))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('auction.start_time')))]),_c('td',[_vm._v(_vm._s(_vm.display.start_time.slice(11, 16)))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('auction.end_time')))]),_c('td',[_vm._v(_vm._s(_vm.display.end_time.slice(11, 16)))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('auction.insurance_value')))]),_c('td',[_vm._v(_vm._s(_vm.display.insurance_value))])]),(_vm.display.auction_horses.length != 0)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('auction.horse')))]),_c('td',[_c('ul',_vm._l((_vm.display.auction_horses),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item.name.en))])}),0)])]):_vm._e(),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('auction.location')))]),_c('td',[_c('div',{staticStyle:{"margin":"20px 0px"}},[_vm._v(" "+_vm._s(_vm.display.location_name)+" ")]),_c('GmapMap',{staticStyle:{"height":"200px","width":"400px"},attrs:{"center":{ lat: _vm.marker.lat, lng: _vm.marker.lng },"zoom":12,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":_vm.marker}})],1)],1)])])]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }