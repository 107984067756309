<template>
    <div>
        <v-text-field
            v-model="verficationBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('verfication.headers')"
            :items="verficationList"
            :search="verficationBase.search"
            class="my-table"
            :loading="verficationBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <!-- <template v-slot:[`item.user`]="{ item }">
                <div class="clicked" @click="showUser(item.user)">{{item.user.first_name}} {{item.user.last_name}}</div>
            </template>
            <template v-slot:[`item.specialist`]="{ item }">
                <div class="clicked" @click="showTrainer(item.specialist)">{{item.specialist.first_name}} {{item.specialist.last_name}}</div>
            </template>
            <template v-slot:[`item.service`]="{ item }">
                <div class="clicked" @click="showService(item)">{{item.service.name}}</div>
            </template> -->
        </v-data-table>
        <!-- <v-dialog v-model="dialogUser" max-width="600px">
            <v-card style="padding-bottom: 30px">
                <v-card-title>
                    {{$t('user_details')}}
                </v-card-title>
                <v-divider />
                <v-spacer />
                <v-card-text>
                    <v-simple-table style="padding-top: 0px;">
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <th>Full Name</th>
                                    <td>{{ user.first_name }} {{ user.last_name}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t('user.email') }}</th>
                                    <td>{{ user.email }} </td>
                                </tr>
                                <tr>
                                    <th>{{ $t('user.gender') }}</th>
                                    <td>{{ user.gender }} </td>
                                </tr>
                                <tr>
                                    <th>Mobile</th>
                                    <td>{{ user.mobile }} </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-spacer />
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTrainer" max-width="600px">
            <v-card style="padding-bottom: 30px">
                <v-card-title>
                    {{$t('trainer_details')}}
                </v-card-title>
                <v-divider />
                <v-spacer />
                <v-card-text>
                    <v-simple-table style="padding-top: 0px;">
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <th>Full Name</th>
                                    <td>{{ trainer.first_name }} {{ trainer.last_name}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t('trainer.email') }}</th>
                                    <td>{{ trainer.email }} </td>
                                </tr>
                                <tr>
                                    <th>{{ $t('trainer.gender') }}</th>
                                    <td>{{ trainer.gender }} </td>
                                </tr>
                                <tr>
                                    <th>Mobile</th>
                                    <td>{{ trainer.mobile }} </td>
                                </tr>
                                <tr>
                                    <th>Birth Date</th>
                                    <td>{{ trainer.date_of_birth }} </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-spacer />
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogService" max-width="600px">
            <v-card style="padding-bottom: 30px">
                <v-card-title>
                    {{$t('service_details')}}
                </v-card-title>
                <v-divider />
                <v-spacer />
                <v-card-text>
                    <v-simple-table style="padding-top: 0px;">
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{{ service.service.name }}</td>
                                </tr>
                                <tr>
                                    <th>Price</th>
                                    <td>{{ service.service.price }}</td>
                                </tr>
                                <tr>
                                    <th>Process Time</th>
                                    <td>{{ service.stableService.process_time }} </td>
                                </tr>
                                <tr>
                                    <th>Employess Number</th>
                                    <td>{{ service.stableService.employees_nb }} </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-spacer />
            </v-card>
        </v-dialog> -->
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useVerficationStore } from "../store/VerficationStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            user: null,
            trainer: null,
            service: null,
            dialogUser: false,
            dialogTrainer: false,
            dialogService: false,
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useVerficationStore, ['verficationBase', 'verficationList']),
    },

    methods: {
        ...mapActions(useVerficationStore, ['fetchAllVerfication', 'deleteVerfication']),
        showUser(user) {
            this.user = user
            this.dialogUser = true
        },
        showTrainer(trainer) {
            this.trainer = trainer
            this.dialogTrainer = true
        },
        showService(item) {
            this.service = item
            this.dialogService = true
        },
    },
    mounted() {
        this.fetchAllVerfication()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
.clicked:hover{
    cursor: pointer;
    color: var(--v-secondary-base);
}
</style>