<template>
    <div>
        <v-text-field
            v-model="trainerBase.search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            :reverse="getDirection"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="$t('trainer.headers')"
            :items="trainerList"
            :search="trainerBase.search"
            class="my-table"
            :loading="trainerBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('trainer.email')}}: </span> {{ item.email }}
                    <br />
                    <span style="color: var(--v-primary-base); font-weight: bold;">  {{ $t('trainer.date_of_birth')}}: </span> {{ item.date_of_birth.slice(0, 10) }}
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="trainerBase.dialogDeleteModel"
                    :loading="trainerBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <display-image-dialog :dialog-image.sync="dialogImageModel" :image="image"></display-image-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.user_image`]="{ item }">
                <v-img
                    @click="displayImage(item)"
                    aspect-ratio="1"
                    :src="item.user_image"
                    :lazy-src="item.user_image"
                    class="grey lighten-2"
                    style="cursor: pointer;"
                    max-width="100"
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import DisplayImageDialog from "@/components/dialogs/DisplayImageDialog.vue";
import { useTrainerStore } from "../store/TrainerStore";
import { mapState, mapActions, mapWritableState  } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog, DisplayImageDialog },

    data() {
        return {
            expanded: [],
            dialogImageModel: false
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useTrainerStore, ['trainerBase', 'trainerList']),
        ...mapWritableState(useTrainerStore, ['image']),
    },

    methods: {
        ...mapActions(useTrainerStore, ['fetchAllTrainer', 'deleteTrainer', 'changeIsActiveTrainer' ]),

        deleteItem(item) {
            this.trainerBase.editedIndex = this.trainerList.indexOf(item)
            this.trainerBase.delete = item
            this.trainerBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.trainerBase.loading = true
            this.deleteTrainer(this.trainerBase.delete.id)
        },

        editItem(item) {
            this.trainerBase.ifEdit = true
            this.$router.push("/trainer/edit/" + item.id)
        },
         
        displayImage(item) {
            this.image = item.user_image
            this.dialogImageModel = true
        },


    },
    mounted() {
        this.fetchAllTrainer()
    }
};
</script>
<style>
@import url(@/assets/Css/main.css);
</style>