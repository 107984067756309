<template>
    <v-form
        ref="form"
        class="form"
        v-if="(horseBase.ifEdit && !horseBase.loadingFetchItem) || !horseBase.ifEdit"
    >
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.name_en" label="horse.name_en" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.name_ar" label="horse.name_ar" />
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <select-input
                    :model.sync="horseModel.gender"
                    label="horse.gender"
                    :items="genderList"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.color_en" label="horse.color_en" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.color_ar" label="horse.color_ar" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.location" label="horse.location" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.adress" label="horse.adress" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="horseModel.owner" label="horse.owner" :ifNotReq="true" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input
                    :text.sync="horseModel.owner_mobile"
                    label="horse.owner_mobile"
                    :ifNotReq="true"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <number-input :number.sync="horseModel.price" label="horse.price" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <number-input
                    :number.sync="horseModel.insurance_value"
                    label="horse.insurance_value"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <date-input
                    :date.sync="horseModel.birth_date"
                    label="horse.birth_date"
                    :allowedDates="allowedDates"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <select-input
                    :model.sync="horseModel.category_id"
                    label="horse.category"
                    :items="horseCategoryList"
                    item_text="name"
                    item_value="id"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <textarea-input
                    :text.sync="horseModel.description_en"
                    label="horse.description_en"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <textarea-input
                    :text.sync="horseModel.description_ar"
                    label="horse.description_ar"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-file-input
                    chips
                    multiple
                    counter
                    outlined
                    :reverse="getDirection"
                    v-model="horseModel.images"
                    :rules="[horseBase.ifEdit ? noRule : requiredRule, imageArrayRule]"
                    :label="$t('horse.images')"
                    append-icon="mdi-camera"
                    prepend-icon
                    @change="changeImages"
                ></v-file-input>
                <v-carousel
                    cycle
                    height="200"
                    show-arrows-on-hover
                    style="border-radius: 15px"
                    v-if="displayImages.length != 0"
                >
                    <v-carousel-item v-for="(item, index) in displayImages" :key="index">
                        <v-img
                            :aspect-ratio="16/9"
                            contain
                            max-height="200"
                            :src="item"
                            :lazy-src="item"
                            class="grey lighten-3"
                            style="margin-bottom: 30px;border-radius: 15px"
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
            <v-col cols="12">
                <submit-btn @click="submit" :loading="horseBase.loading" />
            </v-col>
        </v-row>
    </v-form>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useHorseStore } from "../store/HorseStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from '@/components/inputs/NumberInput.vue'
import { useHorseCategoryStore } from "@/modules/horse_category/store/HorseCategoryStore.js"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        TextareaInput,
        SelectInput,
        Loader,
        DateInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            showPassword: false,
            // displayImages: []
        };
    },
    computed: {
        ...mapState(useLangStore, ["lang", "getDirection"]),
        ...mapState(useHorseStore, ["horseBase", "horseModel", 'displayImages', 'genderList']),
        ...mapState(useHorseCategoryStore, ['horseCategoryList']),
        // displayImages(){
        //     return this.horseModel.images
        // }
    },
    methods: {
        allowedDates: val => val <= new Date().toJSON().slice(0, 10),
        ...mapActions(useHorseStore, ['addHorse', 'clearHorseModel', 'getHorseById', 'editHorse']),
        ...mapActions(useHorseCategoryStore, ['fetchAllHorseCategory']),
        changeImages(event) {
            this.displayImages = []
            if (event.length != 0) {
                event.forEach(item => {
                    if (item != null || item != undefined)
                        this.displayImages.push(URL.createObjectURL(item))
                })
            }
            else {
                this.displayImages = []
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.horseBase.ifEdit
                    ? this.editHorse(this.horseModel.id, this.createFormData())
                    : this.addHorse(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('name_en', this.horseModel.name_en)
            formData.append('name_ar', this.horseModel.name_ar)
            formData.append('category_id', this.horseModel.category_id)
            formData.append('color_en', this.horseModel.color_en)
            formData.append('color_ar', this.horseModel.color_ar)
            formData.append('location', this.horseModel.location)
            formData.append('gender', this.horseModel.gender)
            formData.append('birth_date', this.horseModel.birth_date)
            formData.append('owner', this.horseModel.owner)
            formData.append('owner_mobile', this.horseModel.owner_mobile)
            formData.append('price', this.horseModel.price)
            formData.append('adress', this.horseModel.adress)
            formData.append('description_en', this.horseModel.description_en)
            formData.append('description_ar', this.horseModel.description_ar)
            formData.append('insurance_value', this.horseModel.insurance_value)
            formData.append('user_id', JSON.parse(localStorage.getItem('user')).id)
            this.horseModel.images.forEach((item, index) => {
                formData.append(`images[${index}]`, item)
            })
            return formData
        },
        initEdit() {
            const id = this.$route.params.id
            this.horseBase.ifEdit = true
            this.getHorseById(id)
        },
        initAdd() {
            this.horseBase.ifEdit = false
            this.clearHorseModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.$route.name === "edit-horse" ? this.initEdit() : this.initAdd()
        this.fetchAllHorseCategory()
    }
};
</script>
<style>
</style>
