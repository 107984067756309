import Service from "@/modules/service/pages/ListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/service",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "service",
            component: Service,
            meta: { title: "Services" }
        }
    ]
};
