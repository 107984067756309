<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :cardTitle="cardTitle" :btnTitle="btnTitle" routeName="add-trainer">
            <template v-slot:dataTable>
                <div v-if="!trainerBase.errorFetch">
                    <trainer-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="trainerBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useTrainerStore } from "../store/TrainerStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import TrainerTable from "../components/TrainerTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        TrainerTable,
        NoInternet,
    },
    data: () => ({
        cardTitle: "trainer.list",
        btnTitle: "trainer.add",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Trainer',
                disabled: false,
                href: '/trainer',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'المدربين ',
                disabled: false,
                href: '/trainer',
            }
        ],
    }),
    computed: {
        ...mapState(useTrainerStore, ['trainerBase']),
    },
    methods: {
        ...mapActions(useTrainerStore, ['fetchAllTrainer']),
        onRetry() {
            this.fetchAllTrainer()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
