import { User } from "@/modules/auth/models/UserModel";
import { defineStore } from "pinia";
import authRepository from "../services/AuthRepository";
import router from "@/router"

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    user:
      localStorage.getItem("user") === null ? null : JSON.parse(localStorage.getItem("user")),
    loading: false,
    error: false,
    message: '',
    permission: [],
    stablePermission: ['Dashboard', 'My Stable', 'Add Stable', 'Edit Stable', 'Trainers', 'Horses', 'Booking', 'Page not found', 'Login', 'My Profile', 'Auctions'],
    adminPermission: ['Dashboard', 'Categories', 'Services', 'Stables', 'Users',  'Horse Categories', 'Horses', 'Page not found', 'Login', 'My Profile', 'Auctions', 'Auction Requests', 'Stable Verfication']
  }),
  getters: {
    isAuthenticated() {
      return this.user !== null;
    },
    getUser() {
      return this.user !== null ? this.user : null;
    },
    getId() {
      return this.user !== null ? this.user.id : null;
    },
    getToken() {
      return this.user !== null ? this.user.token : null;
    },
  },
  actions: {
    loginLocally(data) {
      // console.log(data)
      const user = {
        id: data.user.id,
        email: data.user.email,
        name: data.user.first_name,
        token: data.accessToken,
        role: data.user.role_id,
        stable_id: data.user.stable_id,
      }
      // console.log(user)
      this.user = new User(user)
      // console.log(this.user)
      localStorage.setItem("user", JSON.stringify(user))
      this.setPermission(data.user.role_id)
    },
    setPermission(role_id) {
      if (role_id == 4)
        localStorage.setItem('permission', JSON.stringify(this.stablePermission))
      else if (role_id == 1)
        localStorage.setItem('permission', JSON.stringify(this.adminPermission))
    },
    login(formData) {

      this.loading = true;
      authRepository
        .login(formData)
        .then((res) => {
          this.loading = false
          // console.log(res)
          this.loginLocally(res.data)
          if(JSON.parse(localStorage.getItem('user')).role == 1)
            router.push("/")
          else
            router.push('/my-stable')
          // router.go(router.currentRoute);
        })
        .catch((err) => {
          // console.log(err)
          this.loading = false

          // this.error = true
          // this.message = err.res.data.errors[0][0]
        });
    },
    logoutLocally() {
      this.user = null
      localStorage.clear()
      router.push("/login")
    },
  },
});
