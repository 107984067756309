<template>
    <div class="login">
        <div class="container">
            <v-card class="login-card row" style="align-items: center;">
                <div class="col-lg-6" style="text-align: justify">
                    <h3 class="my-title">{{ $t('login.title') }}</h3>
                    <v-form style="margin-top: 70px" ref="form">
                        <v-text-field
                            type="email"
                            prepend-inner-icon="mdi-email"
                            outlined
                            :reverse="getDirection"
                            v-model="email"
                            :rules="[requiredRule, emailRule]"
                            :label="$t('login.email')"
                            @keyup.enter="submit()"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            outlined
                            prepend-inner-icon="mdi-key"
                            :reverse="getDirection"
                            v-model="password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            :type="showPassword ? 'text' : 'password'"
                            :rules="[requiredRule, passwordRule]"
                            :label="$t('login.password')"
                            @keyup.enter="submit()"
                            validate-on-blur
                        ></v-text-field>
                        <submit-btn @click="submit" :loading="authStore.loading" style="margin-top: 30px"/>
                    </v-form>
                </div>
                <div class="col-lg-6 img-container">
                    <img src="@/assets/img/login.svg" style="width: 80%" />
                </div>
            </v-card>
        </div>
    </div>
</template>


<script>
import SubmitBtn from "@/components/buttons/SubmitBtn.vue"
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import { useAuthStore } from "../store/AuthStore"

export default {
    name: 'Login',
    components: {
        SubmitBtn,
    },
    setup() {
        // initialize the store
        const authStore = useAuthStore()
        return { authStore }
    },
    mixins: [FormValidationRulesMixin],
    data: () => ({
        email: null,
        password: null,
        showPassword: false,
    }),
    computed: {
        ...mapState(useLangStore, ['lang', 'getDirection']),
        // ...mapState(this.authStore, ['loading']),
    },
    methods: {
        // ...mapActions(this.authStore, ["login"]),

        submit() {
            if (this.$refs.form.validate()) {
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('email', this.email)
            formData.append('password', this.password)

            this.authStore.login(formData)
        },
    },
    mounted() {
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);

.login .my-title {
    color: var(--v-primary-base);
    font-weight: bold;
}

.login .login-card {
    border: none;
    border-radius: 20px;
    padding: 80px;
    margin: 60px 0px 50px;
    box-shadow: 0 0 10px #c5c5c5 !important;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // row-gap: 50px;
}

.login .left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .v-messages__message {
    text-align: justify;
}

@media (max-width: 992px) {
    .login .img-container {
        display: none;
    }
}

.login .img-container {
    display: flex;
    justify-content: center;
}
</style>
