import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/service", formData);
    },

    getAll() {
        return Client.get("/service");
    },

    getById(id) {
        return Client.get(`/service/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/service/${id}`, formData)
    },

    delete(id) {
        return Client.delete(`/service/${id}`);
    },

    changeIsActive(id, formData) {
        return Client.post(`/service/is-active/${id}`, formData)
    },

    changeIsTopService(id, formData) {
        return Client.post(`/service/is-top-service/${id}`, formData)
    }
};