import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        defaultTheme: 'light',
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#5A0072',
                secondary: '#CCA76A',
                accent: '#BE8CCE',
                // primary: '#244879',
                // secondary: '#f3b31d',
                // accent: '#72bc42',
            },
        },
    },
});



