import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import TrainerService from "../services/TrainerService";
import router from "@/router"
export const useTrainerStore = defineStore("trainerStore", {
    state: () => ({
        trainerBase: new BaseState(),
        trainerModel: {
            id: '',
            first_name: '',
            last_name: '',
            email: "",
            password: "",
            mobile: '',
            gender: '',
            date_of_birth: '',
            stable_id: '',
            image: null,
        },
        trainerList: [],
        image: '',
    }),
    getters: {
    },
    actions: {
        clearTrainerModel() {
            this.trainerModel.id = ''
            this.trainerModel.first_name = ""
            this.trainerModel.last_name = ""
            this.trainerModel.email = ''
            this.trainerModel.password = ''
            this.trainerModel.gender = ''
            this.trainerModel.date_of_birth = ''
            this.trainerModel.mobile = ''
            this.trainerModel.image = null
        },
        addTrainer(formData) {
            this.trainerBase.loading = true;
            TrainerService.add(formData).then((res) => {
                this.trainerBase.loading = false
                if (res.data.status != false) {
                    this.clearTrainerModel()
                    router.push('/trainers')
                }
            })
                .catch((err) => {
                    this.trainerBase.loading = false
                    this.trainerBase.error = true
                });
        },
        fetchAllTrainer() {
            this.trainerBase.loadingFetch = true
            TrainerService.getAll().then((res) => {
                this.trainerList = res.data.data.reverse()
                this.trainerBase.loadingFetch = false
                this.trainerBase.errorFetch = false
            })
                .catch((err) => {
                    this.trainerBase.loadingFetch = false
                    this.trainerBase.errorFetch = true
                });
        },
        deleteTrainer(id) {
            this.trainerBase.loading = true
            TrainerService.delete(id).then((res) => {
                this.trainerBase.loading = false
                this.trainerList.splice(this.trainerBase.editedIndex, 1)
                this.trainerBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.trainerBase.loading = false
                    this.trainerBase.error = true
                })
        },
        // getTrainerById(id) {
        //     this.trainerBase.loadingFetchItem = true
        //     TrainerService.getById(id).then((res) => {
        //         this.trainerBase.loadingFetchItem = false
        //         this.trainerModel.id = res.data.list.id
        //         this.trainerModel.first_name = res.data.list.first_name
        //         this.trainerModel.last_name = res.data.list.last_name
        //         this.trainerModel.email = res.data.list.email
        //         this.trainerModel.password = res.data.list.password
        //         this.trainerModel.gender = res.data.list.gender
        //         this.trainerModel.date_of_birth = res.data.list.date_of_birth
        //         this.trainerModel.role_id = res.data.list.role_id
        //     })
        //         .catch((err) => {
        //             this.trainerBase.loadingFetchItem = false
        //             this.trainerBase.error = true
        //         })
        // },
        // editTrainer(id, formData) {
        //     this.trainerBase.loading = true;
        //     TrainerService.edit(id, formData).then((res) => {
        //         this.trainerBase.loading = false
        //         this.trainerBase.ifEdit = false
        //         router.push('/trainer')
        //         this.cleartrainerModel()
        //     })
        //         .catch((err) => {
        //             this.trainerBase.loading = false
        //             this.trainerBase.error = true
        //         });
        // },
    },

});
