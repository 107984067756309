import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import CategoryService from "../services/CategoryService";

export const useCategoryStore = defineStore("categoryStore", {
    state: () => ({
        categoryBase: new BaseState(),
        categoryModel: {
            id: '',
            name_en: '',
            name_ar: '',
            description_en: '',
            description_ar: '',
            image: null,
        },
        categoryList: [],
        image: '',
        show: false
    }),
    getters: {
    },
    actions: {
        clearCategoryModel() {
            this.categoryModel.id = ''
            this.categoryModel.name_en = ""
            this.categoryModel.name_ar = ""
            this.categoryModel.description_en = ""
            this.categoryModel.description_ar = ""
            this.categoryModel.image = null
        },
        setCategoryModel(item) {
            this.categoryModel.id = item.id
            this.categoryModel.name_en = item.name.en
            this.categoryModel.name_ar = item.name.ar
            this.categoryModel.description_en = item.description.en           
            this.categoryModel.description_ar = item.description.ar
            this.categoryModel.image = item.image
        },
        addCategory(formData) {
            this.categoryBase.loading = true;
            CategoryService.add(formData).then((res) => {
                this.categoryBase.loading = false
                this.show = false
                this.fetchAllCategory()
                this.clearCategoryModel()
            })
                .catch((err) => {
                    this.categoryBase.loading = false
                    this.categoryBase.error = true
                });
        },
        fetchAllCategory() {
            this.categoryBase.loadingFetch = true
            CategoryService.getAll().then((res) => {
                // console.log(res.data.data)
                this.categoryList = res.data.data.reverse()
                this.categoryBase.loadingFetch = false
                this.categoryBase.errorFetch = false
            })
                .catch(() => {
                    this.categoryBase.loadingFetch = false
                    this.categoryBase.errorFetch = true
                });
        },
        deleteCategory(id) {
            this.categoryBase.loading = true
            CategoryService.delete(id).then((res) => {
                this.categoryBase.loading = false
                this.categoryList.splice(this.categoryBase.editedIndex, 1)
                this.categoryBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.categoryBase.loading = false
                    this.categoryBase.error = true
                })
        },
        getCategoryById(id) {
            this.categoryBase.loadingFetchItem = true
            CategoryService.getById(id).then((res) => {
                this.categoryBase.loadingFetchItem = false
                this.categoryModel.id = res.data.data.id
                this.categoryModel.name_en = res.data.data.name.en
                this.categoryModel.name_ar = res.data.data.name.ar
                this.categoryModel.description_en = res.data.data.description.en
                this.categoryModel.description_ar = res.data.data.description.ar
                this.categoryModel.image = res.data.data.image
            })
                .catch((err) => {
                    this.categoryBase.loadingFetchItem = false
                })
        },
        editCategory(id, formData) {
            this.categoryBase.loading = true;
            CategoryService.edit(id, formData).then((res) => {
                this.categoryBase.loading = false
                this.categoryBase.ifEdit = false
                this.show = false
                this.fetchAllCategory()
                this.clearCategoryModel()
            })
                .catch((err) => {
                    this.categoryBase.loading = false
                    this.categoryBase.error = true
                });
        },
    },
});
