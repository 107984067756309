import ar from "@/assets/i18n/ar.json";
import en from "@/assets/i18n/en.json";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { useLangStore } from "@/store/LangStore";
import pinia from "@/store";

Vue.use(VueI18n);

const langStore = useLangStore(pinia);

const i18n = new VueI18n({
    locale: langStore.getLang,
    messages: {
        en: en,
        ar: ar
    }
});

langStore.$i18n = i18n;

export default i18n;