<template>
    <div class="list">
        <breadcrumbs :breadcrumbs="breadEn" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="breadAr" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t( `${cardTitle}`) }}
                <!-- <edit-btn :title="btnTitle" routeName="edit-stable" /> -->
                <v-btn @click="edit" color="primary" outlined>
                    {{ $t('stable.edit') }}
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>
            <div class="card-body">
                <div v-if="!stableBase.errorFetch">
                    <my-stable-details />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="stableBase.loadingFetch"></no-internet>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useStableStore } from "../store/StableStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import MyStableDetails from "../components/MyStableDetails.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import EditBtn from "@/components/buttons/EditBtn.vue"
import { useLangStore } from "@/store/LangStore"

export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        MyStableDetails,
        NoInternet,
        EditBtn
    },
    data: () => ({
        cardTitle: "stable.list",
        btnTitle: "stable.edit",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'My Stable',
                disabled: false,
                href: '/my-stable',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'اسطبلي ',
                disabled: false,
                href: '/stable',
            }
        ],
    }),
    computed: {
        ...mapState(useStableStore, ['stableBase', 'myStable']),
        ...mapState(useLangStore, ['lang']),
    },
    methods: {
        ...mapActions(useStableStore, ['fetchMyStable']),
        onRetry() {
            this.fetchMyStable()
        },
        edit(){
            this.$router.push(`/my-stable/edit/${this.myStable.id}`)
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
