import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import VerficationService from "../services/VerficationService";

export const useVerficationStore = defineStore("verficationStore", {
    state: () => ({
        verficationBase: new BaseState(),
        verficationList: [],
    }),
    getters: {
    },
    actions: {
        fetchAllVerfication() {
            this.verficationBase.loadingFetch = true
            VerficationService.getAll().then((res) => {
                // console.log(res.data.data)
                this.verficationList = res.data.data.reverse()
                this.verficationBase.loadingFetch = false
                this.verficationBase.errorFetch = false
            })
                .catch((err) => {
                    this.verficationBase.loadingFetch = false
                    this.verficationBase.errorFetch = true
                });
        },
    },
});
