import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import HorseService from "../services/HorseService";
import router from "@/router"
export const useHorseStore = defineStore("horseStore", {
    state: () => ({
        horseBase: new BaseState(),
        horseModel: {
            id: '',
            name_en: '',
            name_ar: '',
            gender: '',
            category_id: '',
            color_en: "",
            color_ar: '',
            location: "",
            owner: '',
            owner_mobile: '',
            price: '',
            adress: '',
            birth_date: '',
            description_en: '',
            description_ar: '',
            insurance_value: '',
            user_id: '',
            images: []
        },
        horseList: [],
        images: '',
        displayImages: [],
        genderList: ["Stallion", "Mare"],
    }),
    getters: {
    },
    actions: {
        clearHorseModel() {
            this.horseModel.id = ''
            this.horseModel.name_en = ""
            this.horseModel.name_ar = ""
            this.horseModel.gender = ''
            this.horseModel.category_id = ''
            this.horseModel.color_en = ''
            this.horseModel.color_ar = ''
            this.horseModel.location = ''
            this.horseModel.owner = ''
            this.horseModel.owner_mobile = ''
            this.horseModel.price = ''
            this.horseModel.adress = ''
            this.horseModel.birth_date = ''
            this.horseModel.description_en = ''
            this.horseModel.description_ar = ''
            this.horseModel.insurance_value = ''
            this.horseModel.user_id = ''
            this.horseModel.images = []
        },
        addHorse(formData) {
            this.horseBase.loading = true;
            HorseService.add(formData).then((res) => {
                this.horseBase.loading = false
                if (res.data.status != false) {
                    this.clearHorseModel()
                    router.push('/horse')
                }
            })
                .catch((err) => {
                    this.horseBase.loading = false
                    this.horseBase.error = true
                });
        },
        fetchAllHorse() {
            this.horseBase.loadingFetch = true
            HorseService.getAll().then((res) => {
                this.horseList = res.data.data.reverse()
                this.horseBase.loadingFetch = false
                this.horseBase.errorFetch = false
            })
                .catch((err) => {
                    this.horseBase.loadingFetch = false
                    this.horseBase.errorFetch = true
                });
        },
        fetchAllHorseAdmin() {
            this.horseBase.loadingFetch = true
            HorseService.getAllHourseAdmin().then((res) => {
                this.horseList = res.data.data.reverse()
                this.horseBase.loadingFetch = false
                this.horseBase.errorFetch = false
            })
                .catch((err) => {
                    this.horseBase.loadingFetch = false
                    this.horseBase.errorFetch = true
                });
        },
        deleteHorse(id) {
            this.horseBase.loading = true
            HorseService.delete(id).then((res) => {
                this.horseBase.loading = false
                this.horseList.splice(this.horseBase.editedIndex, 1)
                this.horseBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.horseBase.loading = false
                    this.horseBase.error = true
                })
        },
        getHorseById(id) {
            this.horseBase.loadingFetchItem = true
            HorseService.getById(id).then((res) => {
                this.horseBase.loadingFetchItem = false
                this.horseModel.id = res.data.data.id
                this.horseModel.name_en = res.data.data.name.en
                this.horseModel.name_ar = res.data.data.name.ar
                this.horseModel.category_id = res.data.data.category_id
                this.horseModel.color_en = res.data.data.color.en
                this.horseModel.color_ar = res.data.data.color.ar
                this.horseModel.location = res.data.data.location
                this.horseModel.gender = res.data.data.gender
                this.horseModel.owner = res.data.data.owner
                this.horseModel.owner_mobile = res.data.data.owner_mobile
                this.horseModel.price = res.data.data.price
                this.horseModel.adress = res.data.data.adress
                this.horseModel.birth_date = res.data.data.birth_date
                this.horseModel.description_en = res.data.data.description.en
                this.horseModel.description_ar = res.data.data.description.ar
                this.horseModel.insurance_value = res.data.data.insurance_value
                this.horseModel.user_id = res.data.data.user_id
                this.displayImages = res.data.data.image
            })
                .catch((err) => {
                    this.horseBase.loadingFetchItem = false
                    this.horseBase.error = true
                })
        },
        editHorse(id, formData) {
            this.horseBase.loading = true;
            HorseService.edit(id, formData).then((res) => {
                this.horseBase.loading = false
                if (res.data.status != false) {
                    this.horseBase.ifEdit = false
                    router.push('/horse')
                    this.clearhorseModel()
                }
            })
                .catch((err) => {
                    this.horseBase.loading = false
                    this.horseBase.error = true
                });
        },
    },

});
