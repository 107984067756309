<template>
    <b-navbar style=" justify-content: space-between;">
        <div class="row" style="padding: 10px;">
            <b-nav-item-dropdown>
                <template #button-content>
                    <b-icon icon="person-fill"></b-icon>
                </template>
                <b-dropdown-item @click="profile()">{{$t('profile')}}</b-dropdown-item>
                <b-dropdown-item @click="logoutLocally()">{{$t('logout')}}</b-dropdown-item>
            </b-nav-item-dropdown>
        </div>
        <b-nav-item-dropdown>
            <template #button-content>
                <div
                    style="display: flex; align-items: center; justify-content: center; column-gap: 5px;"
                >
                    <b-icon icon="globe"></b-icon>
                    {{ display_lang }}
                </div>
            </template>
            <b-dropdown-item @click="handleChange('ar')" v-if="lang == 'en'">العربية</b-dropdown-item>
            <b-dropdown-item @click="handleChange('en')" v-if="lang == 'ar'">English</b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { useAuthStore } from "@/modules/auth/store/AuthStore"
import { mapState, mapActions } from "pinia"
export default {
    name: 'Navbar',

    data: () => ({
        id: null,
        load: false,
        data: '',
        count: 0,
    }),
    computed: {
        ...mapState(useLangStore, ['lang', 'direction']),
        display_lang() {
            if (this.lang == "ar")
                return "العربية"
            else
                return "English"
        }
    },
    methods: {
        ...mapActions(useLangStore, ['changeLang']),
        ...mapActions(useAuthStore, ['logoutLocally']),

        handleChange(selectedLang) {
            this.changeLang(selectedLang)
        },

        profile() {
            this.$router.push({ name: 'my-profile' })
        },
    },
    mounted() {
    }
};
</script>

<style lang="scss">
.bi-exclamation-circle {
    color: var(--v-secondary-base);
    font-size: 45px;
    margin-top: 20px;
}
.notification .dropdown-item {
    background-color: #eee;
    padding: 10px 25px;
}

.notification .dropdown-menu {
    width: 320px !important;
}
.navbar .dropdown-menu {
    text-align: justify;
}
.navbar li {
    display: unset;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
    background-color: var(--v-primary-base);
}

.navbar {
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: 0 0 10px #e4e4e4;
    padding: 15px 40px !important;
}

.navbar .bi-person-fill {
    font-size: 25px !important;
}

.navbar .bi-bell-fill {
    font-size: 25px !important;
}

.navbar .nav-link {
    padding: 6px 8px !important;
}

.navbar .bi-globe {
    font-size: 25px !important;
    // padding-top: 1px;
}

.navbar-nav .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
}

.navbar .nav-item {
    margin: 0px 10px;
}

html:lang(ar) .navbar-nav .nav-link {
    direction: rtl;
}

.navbar .dropdown-menu {
    padding: 10px 0px;
}

.navbar .dropdown-toggle::after {
    display: none !important;
}

.navbar .nav-link {
    background-color: #e9e9e9;
    border-radius: 20px;
}
</style>