<template>
    <v-dialog v-model="showUpdated" :max-width="width" :persistent="persistent" style="z-index: 999;">
        <v-card style="padding:10px 0px 20px">
            <v-card-title>
                <span class="text-h5 color-primary">{{ $t(`${title}`) }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-container>
                    <slot name="inputs"></slot>
                </v-container>
            </v-card-text>
            <v-divider />
            <v-card-actions style="column-gap: 10px;">
                <v-spacer></v-spacer>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import { useDialogStore } from "@/store/DialogStore"
// import { mapWritableState } from "pinia"
export default {
    name: "FormDialog",
    props: {
        title: {
            type: String,
            required: true
        },
        persistent: {
            type: Boolean, 
            default: false
        },
        show: {
            type: Boolean,
        },
        width:{
            type: String,
            default: "600px"
        }
    },
    computed: {
        // ...mapWritableState(useDialogStore, ["show"]),
        showUpdated: {
            get(){
                return this.show
            },
            set(value){
                this.$emit('update:show', value)
            }
        }
    },
};
</script>
