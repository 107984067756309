import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import UserService from "../services/UserService";
import router from "@/router"
export const useUserStore = defineStore("userStore", {
    state: () => ({
        userBase: new BaseState(),
        userModel: {
            id: '',
            first_name: '',
            last_name: '',
            email: "",
            password: "",
            gender: '',
            date_of_birth: '',
            role_id: '',
            image: null,
            mobile: '',
        },
        userList: [],
        roleList: [],
        image: '',
    }),
    getters: {
    },
    actions: {
        clearUserModel() {
            this.userModel.id = ''
            this.userModel.first_name = ""
            this.userModel.last_name = ""
            this.userModel.email = ''
            this.userModel.password = ''
            this.userModel.gender = ''
            this.userModel.date_of_birth = ''
            this.userModel.role_id = ''
            this.userModel.user_image = null,
                this.userModel.mobile = ''
        },
        addUser(formData) {
            this.userBase.loading = true;
            UserService.add(formData).then((res) => {
                this.userBase.loading = false
                this.clearUserModel()
                router.push('/user')
            })
                .catch((err) => {
                    this.userBase.loading = false
                    this.userBase.error = true
                });
        },
        fetchAllUser() {
            this.userBase.loadingFetch = true
            UserService.getAll().then((res) => {
                this.userList = res.data.data.reverse()
                this.userBase.loadingFetch = false
                this.userBase.errorFetch = false
            })
                .catch((err) => {
                    this.userBase.loadingFetch = false
                    this.userBase.errorFetch = true
                });
        },
        deleteUser(id) {
            this.userBase.loading = true
            UserService.delete(id).then((res) => {
                this.userBase.loading = false
                this.userList.splice(this.userBase.editedIndex, 1)
                this.userBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.userBase.loading = false
                    this.userBase.error = true
                })
        },
        getUserById(id) {
            this.userBase.loadingFetchItem = true
            UserService.getById(id).then((res) => {
                this.userBase.loadingFetchItem = false
                this.userModel.id = res.data.data.id
                this.userModel.first_name = res.data.data.first_name
                this.userModel.last_name = res.data.data.last_name
                this.userModel.email = res.data.data.email
                this.userModel.password = res.data.data.password
                this.userModel.gender = res.data.data.gender
                this.userModel.date_of_birth = res.data.data.date_of_birth
                this.userModel.role_id = res.data.data.role_id
                this.userModel.image = res.data.data.user_image
                this.userModel.mobile = res.data.data.mobile
            })
                .catch((err) => {
                    this.userBase.loadingFetchItem = false
                    this.userBase.error = true
                })
        },
        editUser(id, formData, ifProfile) {
            this.userBase.loading = true;
            UserService.edit(id, formData).then((res) => {
                this.userBase.loading = false
                this.userBase.ifEdit = false
                if (ifProfile)
                    router.push('/my-profile')
                else
                    router.push('/user')
                this.clearuserModel()
            })
                .catch((err) => {
                    this.userBase.loading = false
                    this.userBase.error = true
                });
        },
        changeIsActiveUser(id, formData) {
            UserService.changeIsActive(id, formData).then((res) => {
                this.fetchAllService()
            })
                .catch((err) => {
                    this.userBase.error = true
                });
        },
        getRole() {
            UserService.getRole().then((res) => {
                this.roleList = res.data.data
                this.roleList = this.roleList.filter(item => item.id != 2)
            })
        }
    },

});
