<template>
    <v-form ref="form" v-if="(userBase.ifEdit && !userBase.loadingFetchItem) || !userBase.ifEdit">
        <v-row>
            <v-col cols="6">
                <text-input :text.sync="userModel.first_name" label="user.first_name" />
            </v-col>
            <v-col cols="6">
                <text-input :text.sync="userModel.last_name" label="user.last_name" />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    type="email"
                    prepend-inner-icon="mdi-email"
                    outlined
                    :reverse="getDirection"
                    v-model="userModel.email"
                    :rules="[requiredRule, emailRule]"
                    :label="$t('user.email')"
                    validate-on-blur
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-key"
                    :reverse="getDirection"
                    v-model="userModel.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[requiredRule, passwordRule]"
                    :label="$t('user.password')"
                    validate-on-blur
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <select-input
                    :model.sync="userModel.gender"
                    label="user.gender"
                    :items="genderList"
                />
            </v-col>
            <v-col cols="6">
                <date-input
                    :date.sync="userModel.date_of_birth"
                    label="user.date_of_birth"
                    :allowedDates="allowedDates"
                />
            </v-col>
            <v-col cols="6">
                <select-input
                    :model.sync="userModel.role_id"
                    label="user.role"
                    :items="roleList"
                    item_text="name"
                    item_value="id"
                />
            </v-col>
            <v-col cols="6">
                <number-input :number.sync="userModel.mobile" label="user.mobile" />
            </v-col>
            <v-col cols="6">
                <image-input :image.sync="userModel.image" label="image" :ifEdit="userBase.ifEdit" />
            </v-col>
            <v-col cols="12">
                <submit-btn @click="submit" :loading="userBase.loading" />
            </v-col>
        </v-row>
    </v-form>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useUserStore } from "../store/UserStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        TextareaInput,
        SelectInput,
        Loader,
        DateInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            showPassword: false,
            genderList: ["male", "female"]
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useUserStore, ["userBase", "userModel", "roleList"]),
    },
    methods: {
        allowedDates: val => val <= new Date().toJSON().slice(0, 10),

        ...mapActions(useUserStore, ['addUser', 'editUser', 'clearUserModel', 'getUserById', 'getRole']),
        submit() {
            if (this.$refs.form.validate()) {
                this.userBase.ifEdit
                    ? this.editUser(this.userModel.id, this.createFormData(), false)
                    : this.addUser(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('first_name', this.userModel.first_name)
            formData.append('last_name', this.userModel.last_name)
            formData.append('email', this.userModel.email)
            formData.append('password', this.userModel.password)
            formData.append('gender', this.userModel.gender)
            formData.append('date_of_birth', this.userModel.date_of_birth)
            formData.append('role_id', this.userModel.role_id)
            formData.append('user_image', this.userModel.image)
            formData.append('mobile', this.userModel.mobile)
            return formData
        },
        initEdit() {
            const id = this.$route.params.id
            this.userBase.ifEdit = true
            this.getUserById(id)
        },
        initAdd() {
            this.userBase.ifEdit = false
            this.clearUserModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.$route.name === "edit-user" ? this.initEdit() : this.initAdd()
        this.getRole()
    }
};
</script>
<style>
</style>
