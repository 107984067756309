import User from "@/modules/user/pages/ListView.vue"
import AddUser from "@/modules/user/pages/AddView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
import EditUser from "@/modules/user/pages/AddView.vue"
import MyProfile from "@/modules/user/pages/MyProfile.vue"
import EditMyProfile from "@/modules/user/pages/EditMyProfile.vue"
export default {
    path: "/user",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "user",
            component: User,
            meta: { title: "Users" }
        },
        {
            path: "/user/add",
            name: "add-user",
            component: AddUser,
            meta: { title: "Users" }
        },
        {
            path: "/user/edit/:id",
            name: "edit-user",
            component: EditUser,
            meta: { title: "Users" }
        },
        {
            path: "/my-profile",
            name: "my-profile",
            component: MyProfile,
            meta: { title: "My Profile" }
        },
        {
            path: "/my-profile/edit",
            name: "edit-my-profile",
            component: EditMyProfile,
            meta: { title: "My Profile" }
        },
    ]
};
