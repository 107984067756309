<template>
    <div>
        <base-list-page-layout :breadEn="breadEn" :breadAr="breadAr" :cardTitle="cardTitle" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!bookingBase.errorFetch">
                    <booking-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="bookingBase.loadingFetch"></no-internet>
                </div>
            </template>
        </base-list-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useBookingStore } from "../store/BookingStore"
import { mapActions, mapState } from "pinia"
import BaseListPageLayout from "@/layouts/BaseListPageLayout.vue"
import BookingTable from "../components/BookingTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
export default {
    components: {
        Breadcrumbs,
        BaseListPageLayout,
        BookingTable,
        NoInternet,
    },
    data: () => ({
        btnTitle: "booking.add",
        cardTitle: "booking.list",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Booking',
                disabled: false,
                href: '/booking',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'الحجوزات',
                disabled: false,
                href: '/booking',
            }
        ],
    }),
    computed: {
        ...mapState(useBookingStore, ['bookingBase']),
    },
    methods: {
        ...mapActions(useBookingStore, ['fetchAllBooking']),
        onRetry() {
            this.fetchAllBooking()
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
