<template>
    <v-form
        ref="form"
        v-if="(auctionBase.ifEdit && !auctionBase.loadingFetchItem) || !auctionBase.ifEdit"
    >
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="auctionModel.title_en" label="auction.title_en" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="auctionModel.title_ar" label="auction.title_ar" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <text-input :text.sync="auctionModel.location_name" label="auction.location_name" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <select-input
                    :model.sync="auctionModel.horse_id"
                    label="auction.horse"
                    :items="horseList"
                    item_text="name"
                    item_value="id"
                    :multiple="true"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <date-input
                    :date.sync="auctionModel.date"
                    label="auction.date"
                    :allowedDates="allowedDates"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <time-input :time.sync="auctionModel.start_time" label="auction.start_time" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <time-input :time.sync="auctionModel.end_time" label="auction.end_time" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <number-input
                    :number.sync="auctionModel.insurance_value"
                    label="auction.insurance_value"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <number-input
                    :number.sync="auctionModel.auction_subscription"
                    label="auction.auction_subscription"
                />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <map-input :marker.sync="auctionModel.marker" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <textarea-input :text.sync="auctionModel.description_en" label="auction.description_en" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <textarea-input :text.sync="auctionModel.description_ar" label="auction.description_ar" />
            </v-col>
            <v-col cols="12">
                <submit-btn @click="submit" :loading="auctionBase.loading" />
            </v-col>
        </v-row>
    </v-form>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useAuctionStore } from "../store/AuctionStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions } from "pinia"
import { useHorseStore } from "@/modules/horse/store/HorseStore"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import MapInput from "@/components/inputs/MapInput.vue"
import TimeInput from '@/components/inputs/TimeInput.vue'
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from '@/components/inputs/NumberInput.vue'

export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        TextareaInput,
        SelectInput,
        Loader,
        MapInput,
        TimeInput,
        NumberInput,
        DateInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            role: null,
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useAuctionStore, ["auctionBase", "auctionModel"]),
        ...mapState(useHorseStore, ['horseList']),
    },
    methods: {
        ...mapActions(useAuctionStore, ['addAuction', 'editAuction', 'clearAuctionModel', 'getAuctionById']),
        ...mapActions(useHorseStore, ['fetchAllHorseAdmin', 'fetchAllHorse']),
        allowedDates: val => val >= new Date().toJSON().slice(0, 10),

        submit() {
            if (this.$refs.form.validate()) {
                this.auctionBase.ifEdit
                    ? this.editAuction(this.auctionModel.id, this.createFormData())
                    : this.addAuction(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('title_en', this.auctionModel.title_en)
            formData.append('title_ar', this.auctionModel.title_ar)
            formData.append('date', this.auctionModel.date)
            formData.append('description_en', this.auctionModel.description_en)
            formData.append('description_ar', this.auctionModel.description_ar)
            formData.append('latitude', this.auctionModel.marker.lat)
            formData.append('longitude', this.auctionModel.marker.lng)
            formData.append('start_time', this.auctionModel.date + ' ' + this.auctionModel.start_time)
            formData.append('end_time', this.auctionModel.date + ' ' + this.auctionModel.end_time)
            formData.append('location_name', this.auctionModel.location_name)
            formData.append('auction_subscription', this.auctionModel.auction_subscription)
            formData.append('insurance_value', this.auctionModel.insurance_value)
            formData.append('user_id', JSON.parse(localStorage.getItem('user')).id)
            if (this.role == 1)
                formData.append('status', 'Accepted')
            this.auctionModel.horse_id.forEach((item, index) => {
                formData.append(`horse_id[${index}]`, item)
            })
            return formData
        },
        initEdit() {
            const id = this.$route.params.id
            this.auctionBase.ifEdit = true
            this.getAuctionById(id)
        },
        initAdd() {
            this.auctionBase.ifEdit = false
            this.clearAuctionModel()
            this.$refs.form.resetValidation()
        },
    },
    mounted() {
        this.$route.name === "edit-auction" ? this.initEdit() : this.initAdd()
        this.role = JSON.parse(localStorage.getItem('user')).role
        if (this.role == 1)
            this.fetchAllHorseAdmin()
        else
            this.fetchAllHorse()
    }
};
</script>
<style>
</style>
