import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/horse", formData);
    },

    getAll() {
        return Client.get("/horse");
    },

    getAllHourseAdmin() {
        return Client.get("/horse-admin");
    },

    getById(id) {
        return Client.get(`/horse/${id}`);
    },

    edit(id, formData) {
        return Client.post(`/horse/${id}`, formData);
    },

    delete(id) {
        return Client.delete(`/horse/${id}`);
    },

};