import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import ServiceService from "../services/ServiceService";

export const useServiceStore = defineStore("serviceStore", {
    state: () => ({
        serviceBase: new BaseState(),
        serviceModel: {
            id: '',
            name_en: '',
            name_ar: '',
            price: '',
            image: null,
            is_active: '',
            is_top_service: '',
        },
        serviceList: [],
        image: '',
        show: false
    }),
    actions: {
        clearServiceModel() {
            this.serviceModel.id = ''
            this.serviceModel.name_en = ""
            this.serviceModel.name_ar = ""
            this.serviceModel.price = ""
            this.serviceModel.image = null
            this.serviceModel.is_active = ''
            this.serviceModel.is_top_service = ''
        },
        setServiceModel(item) {
            this.serviceModel.id = item.id
            this.serviceModel.name_en = item.name_en
            this.serviceModel.name_ar = item.name_ar
            this.serviceModel.price = item.price
            this.serviceModel.image = item.image
            this.serviceModel.is_active = item.is_active
            this.serviceModel.is_top_service = item.is_top_service
        },
        addService(formData) {
            this.serviceBase.loading = true;
            ServiceService.add(formData).then((res) => {
                this.serviceBase.loading = false
                this.show = false
                this.fetchAllService()
                this.clearServiceModel()
            })
                .catch((err) => {
                    this.serviceBase.loading = false
                    this.serviceBase.error = true
                });
        },
        fetchAllService() {
            this.serviceBase.loadingFetch = true
            ServiceService.getAll().then((res) => {
                // console.log(res.data.data)
                this.serviceList = res.data.data.reverse()
                this.serviceBase.loadingFetch = false
                this.serviceBase.errorFetch = false
            })
                .catch((err) => {
                    this.serviceBase.loadingFetch = false
                    this.serviceBase.errorFetch = true
                });
        },
        deleteService(id) {
            this.serviceBase.loading = true
            ServiceService.delete(id).then((res) => {
                this.serviceBase.loading = false
                this.serviceList.splice(this.serviceBase.editedIndex, 1)
                this.serviceBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.serviceBase.loading = false
                    this.serviceBase.error = true
                })
        },
        getServiceById(id) {
            this.serviceBase.loadingFetchItem = true
            ServiceService.getById(id).then((res) => {
                this.serviceBase.loadingFetchItem = false
                this.serviceModel.id = res.data.data.id
                this.serviceModel.name_en = res.data.data.name.en
                this.serviceModel.name_ar = res.data.data.name.ar
                this.serviceModel.price = res.data.data.price
                this.serviceModel.image = res.data.data.image
                this.serviceModel.is_active = res.data.data.is_active
                this.serviceModel.is_top_service = res.data.data.is_top_service
            })
                .catch((err) => {
                    this.serviceBase.loadingFetchItem = false
                })
        },
        editService(id, formData) {
            this.serviceBase.loading = true;
            ServiceService.edit(id, formData).then((res) => {
                this.serviceBase.loading = false
                this.serviceBase.ifEdit = false
                this.show = false
                this.fetchAllService()
                this.clearServiceModel()
            })
                .catch((err) => {
                    this.serviceBase.loading = false
                    this.serviceBase.error = true
                });
        },
        changeIsActiveService(id, formData) {
            ServiceService.changeIsActive(id, formData).then((res) => {
                this.fetchAllService()
            })
                .catch((err) => {
                    this.serviceBase.error = true
                });
        },
        changeIsTopService(id, formData) {
            ServiceService.changeIsTopService(id, formData).then((res) => {
                this.fetchAllService()
            })
                .catch((err) => {
                    this.serviceBase.error = true
                });
        },
    },
});
