export class User {
  id;
  name;
  token;
  email;
  role;
  stable_id;

  constructor(data) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.token = data.token;
    this.role = data.role;
    this.stable_id = data.stable_id;
  }
}
