<template>
    <div>
        <base-add-page-layout
            :breadEn="horseBase.ifEdit ? breadEditEn : breadAddEn"
            :breadAr="horseBase.ifEdit ? breadEditAr : breadAddAr"
            :cardTitle="horseBase.ifEdit ? cardTitleEdit : cardTitleAdd"
            :btnTitle="btnTitle"
            listRouteName="horse"
        >
            <template v-slot:form>
                <horse-form />
            </template>
        </base-add-page-layout>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs.vue"
import { useHorseStore } from "../store/HorseStore"
import { mapState } from "pinia"
import BaseAddPageLayout from "@/layouts/BaseAddPageLayout.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import HorseForm from "../components/HorseForm.vue"
export default {
    components: {
        Breadcrumbs,
        BaseAddPageLayout,
        NoInternet,
        HorseForm,
    },
    data: () => ({
        cardTitleAdd: "horse.add",
        cardTitleEdit: "horse.edit",
        btnTitle: "horse.list",
        breadAddEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Horse',
                disabled: false,
                href: '/horse',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/horse/add',
            },
        ],
        breadAddAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'الاحصنة',
                disabled: false,
                href: '/horse',
            },
            {
                text: 'اضافة',
                disabled: false,
                href: '/horse/add',
            }
        ],
        breadEditEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Horse',
                disabled: false,
                href: '/horse',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        breadEditAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: 'الاحصنة',
                disabled: false,
                href: '/horse',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            }
        ],
    }),
    computed: {
        ...mapState(useHorseStore, ['horseBase']),
    },
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
