import Verfication from "@/modules/stable_verfication/pages/ListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/verfication",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "",
            name: "verfication",
            component: Verfication,
            meta: { title: "Stable Verfication" }
        }
    ]
};
