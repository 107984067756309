<template>
    <form-dialog
        :title="!categoryBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        width="800px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="form" v-if="!categoryBase.loadingFetchItem">
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <text-input :text.sync="categoryModel.name_en" label="category.name_en" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <text-input :text.sync="categoryModel.name_ar" label="category.name_ar" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <textarea-input :text.sync="categoryModel.description_en" label="category.description_en" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <textarea-input :text.sync="categoryModel.description_ar" label="category.description_ar" />
                    </v-col>
                    <v-col cols="12" >
                        <image-input
                            :image.sync="categoryModel.image"
                            label="image"
                            :ifEdit="categoryBase.ifEdit"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <loader v-else />
        </template>
        <template v-slot:actions>
            <cancel-btn @click="cancel" />
            <submit-btn @click="submit" :loading="categoryBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useCategoryStore } from "../store/CategoryStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import ImageInput from '@/components/inputs/ImageInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextareaInput from "@/components/inputs/TextareaInput.vue"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        ImageInput,
        TextInput,
        CancelBtn,
        TextareaInput,
        Loader
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "category.add",
            titleEdit: "category.edit",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useCategoryStore, ["categoryBase", "categoryModel"]),
        ...mapWritableState(useCategoryStore, ["show"]),
    },
    methods: {
        ...mapActions(useCategoryStore, ['addCategory', 'editCategory', 'clearCategoryModel']),
        submit() {
            if (this.$refs.form.validate()) {
                this.categoryBase.ifEdit
                    ? this.editCategory(this.categoryModel.id, this.createFormData())
                    : this.addCategory(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('name_en', this.categoryModel.name_en)
            formData.append('name_ar', this.categoryModel.name_ar)
            formData.append('description_en', this.categoryModel.description_en)
            formData.append('description_ar', this.categoryModel.description_ar)
            if(this.ifImageUpdated() )
                formData.append('image', this.categoryModel.image)
            return formData
        },
        ifImageUpdated() {
            return (typeof (this.categoryModel.image) != "string") && (this.categoryModel.image != null) ? true : false
        },
        cancel() {
            this.show = false
            this.categoryBase.ifEdit = false
            this.clearCategoryModel()
            this.$refs.form.resetValidation()
        }
    }
};
</script>

<style>
.form .col-12{
    padding: 0px 15px !important
}
</style>